import React, { ReactNode } from 'react';
import './styles.scss';
import classNames from 'classnames';

interface TitleProps {
    children: ReactNode;
    className?: string;
}

export const Title = (props: TitleProps) => (
    <h1 className={classNames("title", props.className)}>{props.children}</h1>
);

interface SubTitleProps {
    children: ReactNode;
    sm?: boolean;
    className?: string;
}

export const SubTitle = (props: SubTitleProps) => (
    <h3 className={classNames("sub-title", props.className, !!props.sm ? "small" : null)}>{props.children}</h3>
);

interface MessageProps {
    children: ReactNode;
    sm?: boolean;
    className?: string;
    bold?: boolean;
    textCenter?: boolean;
    small?: boolean;
}

export const Message = (props: MessageProps) => (
    <p className={classNames("message", {'bold': props.bold, 'text-center': props.textCenter, 'message--small': props.small }, props.className)}>
        {props.children}
    </p>
);