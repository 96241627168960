import React from 'react';
import './index.scss';
import classNames from "classnames";

interface TitleProps {
    text: string;
    sm?: boolean;
    md?: boolean;
    lg?: boolean;
    className?: string;
    primary?: boolean;
    secondary?: boolean;
    tertiary?: boolean;
}

export const Title = function (props: TitleProps) {
    const className = props.className;
    const color = props.tertiary ? "white" : props.secondary ? "light-blue" : "dark-blue";
    const classes = classNames(className, color, "mx-sm-auto");
    let element = props.sm ? <h3 className={classes}>{props.text}</h3>
        : props.md ? <h2 className={classes}>{props.text}</h2>
            : <h1 className={classes}>{props.text}</h1>;
    return (
        <div className="d-flex">
            {element}
        </div>
    );
};

interface IText {
    text: string;
    sm?: boolean;
    md?: boolean;
    lg?: boolean;
    className?: string;
    style?: object;
    primary?: boolean;
    secondary?: boolean;
    tertiary?: boolean;
}

export const Text = function (props: IText) {
    const classes = classNames(props.className);
    return (
        <p className={classes} style={props.style}>
            {props.text}
        </p>
    );
};
