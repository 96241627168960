import React, { useState } from 'react';
import { connect } from 'react-redux';
import { API, documentType } from "../../api";
import { ApplicationState, Document } from "../../store";
import { fileToJsonPayload, hasContent, getUploadErrorMsg } from "../../lib/helpers";
import { Routes, ExternalLinks } from '../../routes';
import { ActionCreators } from '../../store/User';
import UploadButton from "../lib/UploadButton";
import { Title, Message, SubTitle } from '../lib/Copy';
import ExternalLink from '../lib/ExternalLink';
import DashboardPageStepScaffold from '../lib/DashboardPageStepScaffold';

interface StateProps {
    isLoading: boolean;
    uploading?: boolean;
    photoIdUploaded?: boolean;
    photoIdUploadFailed?: boolean;
}

interface DispatchProps {
    postDocument: (document: Document, cloudFileId?: any) => void;
    continueFromRoute: (currentRoute: string) => void;
    postDocumentError: (document: Document) => void;
}

type Props = StateProps & DispatchProps;

const IdentificationPage = (props: Props) => {
    const [errors, setErrors] = useState({
        tooLarge: false,
        wrongFormat: false
    });
    const uploadFile = (file: File, cloudFileId: any) => {
        if (cloudFileId != "uploadError") {
            fileToJsonPayload(file, documentType.photoId)
                .then(payload => {
                    setErrors({ tooLarge: false, wrongFormat: false });
                    props.postDocument(payload as Document, cloudFileId);
                })
                .catch(err => {
                    setErrors({ ...errors, ...err });
                });
        } else {
            fileToJsonPayload(file, documentType.photoId)
            .then(payload => {
                setErrors({ tooLarge: false, wrongFormat: false });
                props.postDocumentError(payload as Document);
            })
            .catch(err => {
                setErrors({ ...errors, ...err });
            });
        }
    };

    return (
        <DashboardPageStepScaffold
            className="identification"
            isLoading={props.isLoading}
            onContinue={() => props.continueFromRoute(Routes.identification)}
        >
            <Title>Photo Identification</Title>
            <Message>We require a valid government issued ID in order to be eligible as a vendor.</Message>
            <SubTitle sm>Don't have a valid photo ID?</SubTitle>
            <ExternalLink
                to={ExternalLinks.identification}
                withCaret
            >
                Get One Here
            </ExternalLink>
            <UploadButton
                id="photo-identification"
                success={props.photoIdUploaded && !props.photoIdUploadFailed}
                uploadFile={uploadFile}
                error={getUploadErrorMsg({ ...errors, uploadFailed: props.photoIdUploadFailed })}
                isUploading={props.uploading}
                documentType={documentType.photoId}
            />
        </DashboardPageStepScaffold>
    );
};

export default connect(
    (state: ApplicationState) => {
        if (state === undefined || state.user === undefined) {
            return {
                isLoading: true,
                photoIdUploaded: false,
                photoIdUploadFailed: false
            };
        }

        const user = state.user;
        const doc = state.documentUpload;
        return {
            isLoading: !hasContent(user.email),
            uploading: doc.photoIdUploading,
            photoIdUploaded: user.photoIdUploaded,
            photoIdUploadFailed: doc.photoIdUploadFailed || user.photoIdUploadFailed
        }
    },
    {
        postDocument: API.postDocument,
        continueFromRoute: ActionCreators.continueFromRoute,
        postDocumentError: API.postDocumentError
    }
)(IdentificationPage);
