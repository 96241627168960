import React, {useEffect, Fragment, ReactNode} from 'react';
import {Title, Message, SubTitle} from '../lib/Copy';
import animationData from '../../assets/complete_animation.json';
import lottie from 'lottie-web';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import DashboardPageStepScaffold from '../lib/DashboardPageStepScaffold';
import PreserveIcon from "../../assets/preserve.png";
import InspectIcon from "../../assets/inspect.png";
import GoogleStore from "../../assets/google_playstore.png";
import iOSStore from "../../assets/ios_store.png";
import Marketplace from "../../assets/marketplace.png";
import VideoTutorial from "../../assets/video_tutorial.png";
import CompletionLogin from "../../assets/completion_login.png";
import {isAndroid, isBrowser, isIOS} from "react-device-detect";
import ExternalLink from '../lib/ExternalLink';
import {ApplicationState} from "../../store";
import {connect} from "react-redux";
import classNames from "classnames";
import ContactInfo from "../../assets/contact_info.jpg";

interface StateProps {
    loading: boolean;
    hasPreserve: boolean;
    hasInspections: boolean;
    isFastTracked: boolean;
}

type Props = StateProps;

const CompletionPage = (props: Props) => {
    useEffect(() => {
        lottie.loadAnimation({
            container: document.getElementById("completion__animation") as Element,
            renderer: 'svg',
            loop: false,
            autoplay: true,
            animationData: animationData
        });
    }, [props.loading]);
    return (
        <DashboardPageStepScaffold className="completion">
            <Row>
                <Col sm={{offset: 1, size: 10}}>
                    <div id="completion__animation" className="mx-auto"/>
                    <Title className="completion__complete mt-15px">Congratulations!</Title>
                    <Message className="completion__reviewing-info">
                        You’ve successfully applied to be part of the most elite vendor
                        network in Mortgage Field Servicing.
                    </Message>
                </Col>
                <Col sm="12">
                {
                    props.isFastTracked ?
                    <>
                    <StepTitle>Step 1: <span className="no-break">Download Our App</span></StepTitle>
                    <div className="completion__apps">
                        {
                            props.hasPreserve ?
                                <StepSectionWithApps
                                    image={PreserveIcon}
                                    title="SafeView&trade; Preserve"
                                    message="SafeView Preserve Mobile is an integrated, multimedia mobile property maintenance app utilized by contractors to receive, assign and complete property preservation work orders. Featuring a smart script to help users navigate work orders with ease, video capabilities in addition to robust 
photo labels and SMS capabilities to alert office staff when work orders
have been submitted."
                                    androidLink="https://play.google.com/store/apps/details?id=com.safeguardproperties.photodirect"
                                    iosLink="https://apps.apple.com/us/app/safeview-preserve/id1499900854?ls=1"
                                />
                                : <Fragment/>
                        }
                        {
                            props.hasInspections ?
                                <StepSectionWithApps
                                    image={InspectIcon}
                                    title="SafeView&trade; Inspect"
                                    message="Safeview™ Inspect Mobile is a real time, mobile inspection application designed to provide full-service field support. Smart scripting enabling customizable survey forms for different work types. Industry first 
multimedia capturing technology. Built in risk mitigation features 
include location based-services."
                                    androidLink="https://play.google.com/store/apps/details?id=com.safeguardproperties.inspi"
                                    iosLink="https://apps.apple.com/us/app/safeview-inspect/id1504445956?ls=1"
                                    className="mt-45px"
                                />
                                : <Fragment/>
                        }
                    </div>
                    <Divider/>
                    
                    <StepTitle>Step 2: <span className="no-break">Log In On the App</span></StepTitle>
                                <StepSection
                                    image={CompletionLogin}
                                    title="Get Started"
                                    message="Using the same login information you created here for your application, log in to our app."
                                >
                                    <Col xs={12} className="completion__log-in--forgot-password">
                                        <SubTitle sm>Forgot your password?</SubTitle>
                                        <p className="mb-2 mt-3">
                                            <ExternalLink to="https://vnet.safeguardproperties.com/RecoverPassword.aspx" withCaret={true}>
                                                Click here to reset your Safeview Preserve&trade; password
                                            </ExternalLink>
                                        </p>
                                        <p>
                                            <ExternalLink to="https://inspi2.safeguardproperties.com/inspi2/lostpassword.php" withCaret={true}>
                                                Click here to reset your Safeview Inspect&trade; password
                                            </ExternalLink>
                                        </p>
                                    </Col>
                                </StepSection>
                    
                    <Divider/>
                    <StepTitle>Step 3: <span className="no-break">Watch the Video</span></StepTitle>
                    <StepSection
                        image={VideoTutorial}
                        title="Safeguard Property Tutorials "
                        message="To understand how to use our applications, and complete our work requests, it’s important to first watch our tutorial videos."
                    />
                    <Divider/>
                    <StepTitle>Step 4: <span className="no-break">Start Doing Work</span></StepTitle>
                    <StepSection
                        image={Marketplace}
                        title="Open Order Marketplace"
                        message="By going to the Open Order Marketplace, you can accept orders and start generating money immediately."
                    />
                    </>
                    :
                    <>
                        <StepTitle>Step 1: <span className="no-break">Download Our App</span></StepTitle>
                        <div className="completion__apps">
                            {
                                props.hasPreserve ?
                                    <StepSectionWithApps
                                        image={PreserveIcon}
                                        title="SafeView&trade; Preserve"
                                        message="SafeView Preserve Mobile is an integrated, multimedia mobile property maintenance app utilized by contractors to receive, assign and complete property preservation work orders. Featuring a smart script to help users navigate work orders with ease, video capabilities in addition to robust 
photo labels and SMS capabilities to alert office staff when work orders
have been submitted."
                                        androidLink="https://play.google.com/store/apps/details?id=com.safeguardproperties.photodirect"
                                        iosLink="https://apps.apple.com/us/app/safeview-preserve/id1499900854?ls=1"
                                    />
                                    : <Fragment/>
                            }
                            {
                                props.hasInspections ?
                                    <StepSectionWithApps
                                        image={InspectIcon}
                                        title="SafeView&trade; Inspect"
                                        message="Safeview™ Inspect Mobile is a real time, mobile inspection application designed to provide full-service field support. Smart scripting enabling customizable survey forms for different work types. Industry first 
multimedia capturing technology. Built in risk mitigation features 
include location based-services."
                                        androidLink="https://play.google.com/store/apps/details?id=com.safeguardproperties.inspi"
                                        iosLink="https://apps.apple.com/us/app/safeview-inspect/id1504445956?ls=1"
                                        className="mt-45px"
                                    />
                                    : <Fragment/>
                            }
                        </div>
                        <Divider/>

                        <StepTitle>Step 2: <span className="no-break">We'll Be In Touch!</span></StepTitle>
                                <StepSection
                                    image={ContactInfo}
                                    title=""
                                    message="Once you’ve downloaded the app, you’re all set. A member of our recruiting team will reach out to you for the next steps in the process."
                                >
                                    <Col xs={12}>
                                    <Message className="completion__app-desc">
                                    If you’d like to get in touch with us, you can reach us by phone or email.
                                    </Message>
                                    </Col>
                                    <br/>
                                    <Col xs={5}>
                                    <Message className="completion__app-desc">
                                    Phone: 216 739 2900 Ext.2192             
                                    </Message>   
                                    </Col>
                                    <Col xs={7}>
                                    <Message className="completion__app-desc">
                                     Email: Recruiting@safeguardproperties.com     
                                     </Message>   
                                    </Col>
                                   
                                </StepSection>
                    </>
                    }

                </Col>
            </Row>
        </DashboardPageStepScaffold>
    );
};

interface StepSectionProps {
    title: string;
    message: string;
    image: any;
    className?: string;
    children?: ReactNode;
}

const StepSection = (props: StepSectionProps) => (
    <Row className={classNames(props.className)}>
        <Col md="2" className="text-md-left text-center">
            <img src={props.image} alt={props.title} style={{width: "95px", height: "95px"}}/>
        </Col>
        <Col md="10">
            <Row>
                <Col md="12" className="completion__app-title">
                    <Title>{props.title}</Title>
                </Col>
                <Col md="12">
                    <Message className="completion__app-desc">
                        {props.message}
                    </Message>
                </Col>
                {props.children}
            </Row>
        </Col>
    </Row>
);

interface StepSectionWithAppProps {
    iosLink: string;
    androidLink: string;
}

const StepSectionWithApps = (props: StepSectionProps & StepSectionWithAppProps) => (
    <StepSection {...props}>
        <Col md="4" className="completion__app-link">
            {
                (isAndroid || isBrowser)
                    ?
                    <ExternalLink to={props.androidLink}>
                        <img
                            src={GoogleStore}
                            alt="Google Play Store"
                            className="completion__app-android"
                        />
                    </ExternalLink>
                    : <Fragment/>
            }
        </Col>
        <Col md="4" className="completion__app-link">
            {
                (isIOS || isBrowser)
                    ?
                    <ExternalLink to={props.iosLink}>
                        <img src={iOSStore} alt="Apple App Store"/>
                    </ExternalLink>
                    : <Fragment/>
            }
        </Col>
    </StepSection>
);

export default connect((state: ApplicationState) => {
    if (state == null) {
        return {
            hasPreserve: false,
            hasInspections: false,
            loading: true,
            isFastTracked: false,
        } as StateProps;
    }

    return {
        hasPreserve: state.user!.workTypeGrassAndSnow || state.user!.workTypeMaintenance,
        hasInspections: state.user!.workTypeInspections,
        loading: false,
        isFastTracked: false,
    } as StateProps;
})(CompletionPage);

interface StepTitleProps {
    children: ReactNode;
}

const StepTitle = (props: StepTitleProps) => (
    <h2 className="completion__step-title text-md-left text-center">{props.children}</h2>
);

const Divider = () => (
    <Col sm="12">
        <hr className="completion__app-desc-separator"/>
    </Col>
);