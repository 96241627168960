import {applyMiddleware, combineReducers, compose, createStore, Middleware} from 'redux';
import thunk from 'redux-thunk';
import {connectRouter, routerMiddleware} from 'connected-react-router';
import {History} from 'history';
import {ApplicationState, reducers} from './';
import {SagaMiddleware} from '@redux-saga/core';
import {uuid4} from "../lib/helpers";

const loggerState = {
    index: 0,
    correlationId: uuid4()
};

const actionLoggerMiddleware: Middleware = state => next => action => {
    // const payload = {...loggerState, action: {...action}};
    // todo: call api to save this action
    loggerState.index++;
    return next(action);
};

export default function configureStore(history: History, sagaMiddleware: SagaMiddleware<{}>, initialState?: ApplicationState) {
    const middleware = [
        actionLoggerMiddleware,
        thunk,
        sagaMiddleware,
        routerMiddleware(history)
    ];

    const rootReducer = combineReducers({
        ...reducers,
        router: connectRouter(history)
    });

    const enhancers = [];
    const windowIfDefined = typeof window === 'undefined' ? null : window as any;
    if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
        enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
    }
    const store = createStore(
        rootReducer,
        initialState,
        compose(applyMiddleware(...middleware), ...enhancers)
    );
    return {store};
}
