import React, { ReactNode } from "react";
import "./styles.scss";
import { DotLoader } from "react-spinners";
import classNames from "classnames";

interface WithLoaderProps {
    isLoading?: boolean;
    title?: string;
    style?: object;
    children?: ReactNode | undefined;
}

export const WithLoader = (props: WithLoaderProps) => {
    const loaderClass = !!props.isLoading ? "loader--visible" : "loader--hidden";
    const loaderBodyClass = !!props.isLoading ? "loader__body--hidden" : "loader__body--visible";
    return (
        <div style={props.style} className={classNames("w-100 h-100")}>
            <div className={classNames("loader d-flex flex-column h-100 justify-content-center align-items-center", loaderClass)}>
                <h2 className="mb-4 text-center">{props.title}</h2>
                <div className="px-0">
                    <DotLoader color="#81B4DF" size="90px" />
                </div>
            </div>
            <div className={loaderBodyClass}>
                {props.children}
            </div>
        </div>
    );
};
