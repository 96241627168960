import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { fileToJsonPayload, getUploadErrorMsg, hasContent } from "../../lib/helpers";
import { Document, ApplicationState, Field } from "../../store";
import { API, documentType } from "../../api";
import { CollapseSection } from '../lib/CollapseSection';
import UploadButton from '../lib/UploadButton';
import RequestButton from '../lib/RequestButton';
import { ActionCreators } from '../../store/User';
import { Title, Message, SubTitle } from '../lib/Copy';
import { Routes } from '../../routes';
import DashboardPageStepScaffold from '../lib/DashboardPageStepScaffold';

interface StateProps {
    isLoading: boolean;
    backgroundCheckDocumentUploaded: boolean;
    backgroundCheckDocumentFailed: boolean;
    uploading: boolean;
    requestBackgroundCheck: boolean;
}

interface DispatchProps {
    postDocument: (document: Document, cloudFileId?: any) => void;
    putField: (field: Field) => void;
    continueFromRoute: (currentRoute: string) => void;
    postDocumentError: (document: Document) => void;
}

type Props = StateProps & DispatchProps;

const BackgroundCheckPage = (props: Props) => {
    const [errors, setErrors] = useState({
        tooLarge: false,
        wrongFormat: false
    });

    const [requestBackgroundCheck, setRequestBackgroundCheck] = useState(props.requestBackgroundCheck);

    useEffect(() => {
        setRequestBackgroundCheck(props.requestBackgroundCheck);
    }, [props.requestBackgroundCheck]);

    const uploadFile = (file: File, cloudFileId: any) => {
        if (cloudFileId != "uploadError") {
            fileToJsonPayload(file, documentType.backgroundCheck)
                .then(payload => {
                    setErrors({ tooLarge: false, wrongFormat: false });
                    props.postDocument(payload as Document, cloudFileId);
                })
                .catch(err => {
                    setErrors({ ...errors, ...err });
                });
        } else {
            fileToJsonPayload(file, documentType.backgroundCheck)
            .then(payload => {
                setErrors({ tooLarge: false, wrongFormat: false });
                props.postDocumentError(payload as Document);
            })
            .catch(err => {
                setErrors({ ...errors, ...err });
            });
        }
    };

    return (
        <DashboardPageStepScaffold
            className="background-check"
            isLoading={props.isLoading}
            onContinue={() => props.continueFromRoute(Routes.backgroundCheck)}
        >
            <Title>Background Check</Title>
            <Message>
                Safeguard’s clients require our contractors pass a background check before visiting their properties. 
                If you have a valid, non-expired background check report you can upload it below.
            </Message>
            <UploadButton
                id="background-check__document"
                title="Upload Background Check"
                className="mt-30px"
                success={props.backgroundCheckDocumentUploaded && !props.backgroundCheckDocumentFailed}
                uploadFile={uploadFile}
                error={
                    getUploadErrorMsg({
                        ...errors,
                        uploadFailed: !requestBackgroundCheck && props.backgroundCheckDocumentFailed
                    })
                }
                isUploading={props.uploading}
                documentType={documentType.backgroundCheck}
            />
        </DashboardPageStepScaffold>
    );
};

const OrSeparator = () => (
    <div className="d-flex justify-content-center align-items-center mt-30px">
        <span className="background-check__or-separator-line" />
        <p style={{ margin: 0, fontWeight: 500 }}>or</p>
        <span className="background-check__or-separator-line" />
    </div>
);

const mapStateToProps = (state: ApplicationState) => {
    if (state === undefined || state.user === undefined) {
        return {
            isLoading: true,
            backgroundCheckDocumentUploaded: false,
            backgroundCheckDocumentFailed: false,
            uploading: false,
            requestBackgroundCheck: false
        };
    }

    var user = state.user;
    const doc = state.documentUpload;
    return {
        isLoading: !hasContent(user.email),
        backgroundCheckDocumentUploaded: user.backgroundCheckUploaded,
        backgroundCheckDocumentFailed: user.backgroundCheckUploadFailed,
        uploading: doc.backgroundCheckUploading,
        requestBackgroundCheck: user.requestBackgroundCheck
    }
};

export default connect(
    mapStateToProps,
    {
        postDocument: API.postDocument,
        putField: (field: Field) => API.putField(field, true),
        continueFromRoute: ActionCreators.continueFromRoute,
        postDocumentError: API.postDocumentError
    }
)(BackgroundCheckPage as any);
