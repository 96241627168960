import React, { Fragment } from "react";
import "./styles.scss";
import classNames from "classnames";
import { CheckIcon } from "../../../assets/icons";

interface RequestButtonProps {
    title: string;
    isClicked: boolean;
    onClick: () => void;
    className?: string;
    subMessage?: string;
}

export default (props: RequestButtonProps) => {
    const buttonStyle = props.isClicked ? "request-button--clicked" : "request-button--not-clicked";
    return (
        <div>
            <div
                className={classNames("request-button", props.className, buttonStyle, "d-flex justify-content-between align-items-center")}
                onClick={() => {
                    props.onClick();
                }}
            >
                <span>
                    {props.title}
                </span>
                {
                    props.isClicked
                        ? <CheckIcon />
                        : <Fragment />
                }
            </div>
            <p className="request-button__sub-message">{props.subMessage}</p>
        </div>
    );
};