import React, {Fragment, useState} from 'react';
import {connect} from 'react-redux';
import {ContinueButton, Email, Form, FormChildProps, getIconColor} from "../Form";
import {API} from "../../api";
import {getRequiredMsg} from "../../lib/helpers";
import {MailIcon} from "../../assets/icons";

interface DispatchProps {
    emailSent: (sent: boolean) => void;
    forgotPassword: (email: string) => void;
}

type Props = DispatchProps;

class RequestForgotPasswordErrors {
    email?: string;
}

const RequestForgotPasswordForm = (props: Props) => {
    const [email, setEmail] = useState('');

    const handleSubmit = (f: Props) => {
        props.forgotPassword(email);
        props.emailSent(true);
    };

    return (
        <div className="request-forgot-password__form">
            <Form
                noReload
                className="d-flex flex-column"
                onSubmit={handleSubmit}
                validate={(f: { email: string }) => {
                    const errors = new RequestForgotPasswordErrors();
                    errors.email = getRequiredMsg(f.email, 'Email');
                    return errors;
                }}
                values={{
                    email
                }}
            >
                {
                    (formProps: FormChildProps) =>
                        <Fragment>
                            <Email
                                name="email"
                                placeHolder="Email"
                                onChange={v => setEmail(v)}
                                value={email}
                                validationMessage={formProps.errors.email}
                                prepend={(focused) =>
                                    <MailIcon
                                        color={getIconColor(focused, formProps.errors.email)}
                                    />
                                }
                                {...formProps}
                            />
                            <ContinueButton className="mt-30px"/>
                        </Fragment>
                }
            </Form>
        </div>
    );
};

export default connect(
    null,
    {
        forgotPassword: API.forgotPassword
    }
)(RequestForgotPasswordForm);
