export const hasContent = (str?: string | null): boolean => (str && str.length > 0) || false;

export const dashToCamelCase = (myStr: string) => {
    return myStr.replace(/-([a-z])/g, function (g) {
        return g[1].toUpperCase();
    });
};


export function uuid4() {
    //@ts-ignore
    let cryptoObj = window.crypto || window.msCrypto; // for IE 11
    
    // source: https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript?page=2&tab=Votes
    function hex(s: string, b: number) {
        return s +
            (b >>> 4).toString(16) +  // high nibble
            (b & 0b1111).toString(16);   // low nibble
    }

    let r = cryptoObj.getRandomValues(new Uint8Array(16));

    r[6] = (r[6] >>> 4) | 0b01000000; // Set type 4: 0100
    r[8] = (r[8] >>> 3) | 0b10000000; // Set variant: 100

    return r.slice(0, 4).reduce(hex, '') +
        r.slice(4, 6).reduce(hex, '-') +
        r.slice(6, 8).reduce(hex, '-') +
        r.slice(8, 10).reduce(hex, '-') +
        r.slice(10, 16).reduce(hex, '-');
}

export interface IFileResult {
    name: string;
    mimeType: string;
    type: string;
    size: number;
    data: any;
}

const allowedMimeTypes = [
    "image/png", 
    "image/jpg", 
    "image/jpeg", 
    "application/pdf"
];
export async function fileToJsonPayload(file: File, type: string): Promise<IFileResult> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve({
            name: file.name,
            mimeType: file.type,
            type,
            size: file.size,
            data: (reader.result as string).split(",")[1]
        });
        reader.onerror = error => reject(error);

        const fiveMegaBytes = 10000000;
        let errors = {tooLarge: false, wrongFormat: false};
        let isError = false;
        if (file.size > fiveMegaBytes) {
            isError = true;
            errors.tooLarge = true;
        }
        if (allowedMimeTypes.findIndex(x => x === file.type) === -1) {
            isError = true;
            errors.wrongFormat = true;
        }
        if (isError) {
            reject(errors);
        }

        reader.readAsDataURL(file);
    });
}

export const getUploadErrorMsg = (errors: {tooLarge?: boolean, wrongFormat?: boolean, uploadFailed?: boolean}) => {
    return !!errors.tooLarge
        ? "File size must be less than 10 Megabytes"
        : !!errors.wrongFormat
            ? "Invalid file format"
                : !!errors.uploadFailed
                    ? "Failed to upload file"
                    : null;
}

export const stringLength = (str?: string): number => {
    if (!str) {
        return 0;
    }
    return str.length;
};

export const
    requiredMsg = (field: string) => `${field} is required`,
    lengthMaxMsg = (field: string, length: number) => `${field} must be less than ${length} characters`,
    lengthExactMsg = (field: string, length: number) => `${field} must be ${length} numbers`,
    getRequiredMsg = (value: string, name: string) => !hasContent(value) ? requiredMsg(name) : undefined,
    getLengthMaxMsg = (value: string, length: number, name: string) => value.length > length ? lengthMaxMsg(name, length) : undefined,
    getLengthExactMsg = (value: string, length: number, name: string) => value.length !== length ? lengthExactMsg(name, length) : undefined;

interface Token {
    userId: string;
    exp: number;
    iss: string;
    aud: string;
}

export const parseJwt = (token: string): Token => {
    try {
        return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
        return {} as Token;
    }
};

export const isJwtExpired = (token: string): boolean => {
    const parsedToken = parseJwt(token);
    const expirationInSeconds = parsedToken.exp;
    const expirationInMs = expirationInSeconds * 1000;
    return expirationInMs < Date.now();
};

export const removeEmbeddedStyles = (text: string) => text.replace(/<style[^>]*>(?:(?!<\/style>)[^])*<\/style>/g, "");

export const isInViewPort = (id: string) => {
    const t = document.getElementById(id);
    if (t === undefined || t === null) throw Error(`Could not find element with id ${id}`);
    const bounding = t.getBoundingClientRect();
    return bounding.top >= 0 &&
        bounding.left >= 0 &&
        bounding.right <= (window.innerWidth || document.documentElement.clientWidth) &&
        bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)
}

export const elementBelowCurrentLocation = (id: string) => {
    const t = document.getElementById(id);
    if (t === undefined || t === null) throw Error(`Could not find element with id ${id}`);
    const bounding = t.getBoundingClientRect();
    return bounding.top > 0;
}
