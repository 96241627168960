import React from 'react';
import { Container, Row, Col } from "reactstrap";
import { Banner } from "../lib/Banner";
import BasicContent from '../lib/BasicContent';


export default () => {
    return (
        <BasicContent className="terms-of-use__wrapper">
            <Banner title={'Terms of Use'} />
            <Container className="terms-of-use">
                <Row className="terms-of-use__body">
                    <Col xs="12"
                        md={{ offset: "1", size: "10" }}
                        lg={{ offset: "2", size: "8" }}>
                        {sections.map((s, index) => <Content key={index} section={s} />)}
                    </Col>
                </Row>
            </Container>
        </BasicContent>
    );
};

const Content = (props: { section: Section }) => (
    <div>
        <h2>{props.section.title}</h2>
        {
            props.section.body.map((body, index) => <p key={index}>{body}</p>)
        }
    </div>
);

interface Section {
    title: string;
    body: string[];
}

const sections = [{
    title: 'Copyright',
    body: ['You should assume that everything you see or read on this Web site is copyrighted unless otherwise noted, and may not be used except as provided in these Terms and Conditions or in the text on the Web site without the written permission of Safeguard Properties ("Safeguard"). Safeguard neither warrants nor represents that your use of the information and materials ("Materials") displayed on this Web site will not infringe on rights of third parties now owned by or affiliated with Safeguard. Images, photographs, or illustrations displayed on the Web site are either the property of or used with permission by Safeguard. The use of these Materials by you, or anyone authorized by you, is prohibited unless specifically permitted by this Agreement or specific permission provided elsewhere on this Web site. Any unauthorized use of the images may violate copyright laws, trademark laws, the laws of privacy and publicity, and communications regulations and statutes.']
}, {
    title: 'Trademarks',
    body: ['The trademarks, logos and service marks (collectively the "Trademarks") displayed on this Web site are the unregistered trademarks of Safeguard, its affiliates and other third parties. Nothing contained on this Web site should be construed as granting, by implication, estoppel or otherwise, any license or right to use any Trademark displayed on the Web site without written permission of Safeguard or such third party that may own the Trademark displayed on the Web site. Your misuse of the Trademarks displayed on the Web site, or any other content on the Web site, except as provided herein, is strictly prohibited.']
}, {
    title: 'Limited License',
    body: [
        'Subject to the terms and conditions set forth in this Agreement, Safeguard grants you a non-exclusive, non-transferable, limited right to access, use and display this site and the materials thereon. You agree not to interrupt or attempt to interrupt the operation of this site in any way.',
        'Safeguard authorizes you to view and download the information ("Materials") at this Web site. However, this authorization is not a transfer of title in the Materials and copies of the Materials and is subject to the following restrictions: (1) you must retain, on all copies of the Materials downloaded, all copyright and other proprietary notices contained in the Materials; (2) you may not modify the Materials in any way or reproduce or publicly display, perform, distribute or otherwise use them for any public or commercial purpose without the written permission of Safeguard; and (3) you must not transfer the Materials to any other person unless you give them notice of, and they agree to accept, the obligations arising under this Agreement. You agree to abide by all additional restrictions displayed on this Web site as may be updated from time to time.'
    ]
}, {
    title: 'Submission',
    body: [
        'All information submitted to Safeguard via this Web site shall be deemed and will remain the property of Safeguard, and Safeguard shall be free to use, for any purpose, any ideas, concepts, know-how or techniques contained in information a visitor to this site provided to Safeguard through this Web Site by a visitor to this Web Site. Safeguard shall not be subject to any obligations of confidentiality regarding submitted information except as specifically agreed by Safeguard or required by law.'
    ]
}, {
    title: 'Third Party Sites',
    body: [
        'As a convenience to you, Safeguard may provide on this Web site links to Web sites operated by other entities. If you decide to visit any linked site, you do so at your own risk and it is your responsibility to take all protective measures to guard against viruses or other destructive elements. Safeguard makes no warranty or representation regarding, and does not endorse, any linked Web sites or the information appearing thereon or any of the products or services described thereon. Links do not imply that Safeguard or this Web Site sponsors, endorses, or is affiliated or associated with linked sites, or is legally authorized to use any trademark, trade name, logo or copyright symbol displayed in or accessible through the links, or that any linked site is authorized to use any trademark, trade name, logo or copyright symbol of Safeguard or any of its affiliates or subsidiaries.'
    ]
}, {
    title: 'Limitations of Liability',
    body: [
        'IN NO EVENT SHALL SAFEGUARD OR ANY OF ITS AFFILIATES OR SUBSIDIARIES BE LIABLE TO ANY ENTITY FOR ANY DIRECT, INDIRECT, SPECIAL, CONSEQUENTIAL OR OTHER DAMAGES (INCLUDING, WITHOUT LIMITATION, ANY LOST PROFITS, BUSINESS INTERRUPTION, LOSS OF INFORMATION, PROGRAMS OR OTHER DATA ON YOUR INFORMATION HANDLING SYSTEM) THAT ARE RELATED TO THE USE OF, OR THE INABILITY TO USE, THE CONTENT, MATERIALS, AND FUNCTIONS OF THE SITE OR ANY LINKED WEBSITE, EVEN IF SAFEGUARD IS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. HYPERLINKS TO OTHER INTERNET RESOURCES ARE USED AT YOUR OWN RISK; THE CONTENT, ACCURACY, OPINIONS EXPRESSED AND OTHER LINKS PROVIDED BY THESE RESOURCES ARE NOT INVESTIGATED, VERIFIED, MONITORED OR ENDORSED BY SAFEGUARD.'
    ]
}, {
    title: 'Changes',
    body: [
        'Safeguard reserves the right, at its sole discretion, to change, modify, add or remove any portion of this Agreement in whole or in part, at any time. Changes in this Agreement will be effective when notice of such change is posted. Your continued use of this Web site after any changes to this Agreement are posted will be considered acceptance of those changes.',
        'Safeguard may terminate, change, suspend or discontinue any aspect of the Safeguard Web site, including the availability of any features of this Web site, at any time. Safeguard may also impose limits on certain features and services or restrict access to parts or all of this Web site without notice or liability. Safeguard may terminate the authorization, rights and license given above and, upon such termination, you shall immediately destroy all Materials.'
    ]
},
] as Section[];
