import React from 'react';
import { CollapseSection } from "../../lib/CollapseSection";
import "./styles.scss";
import { OpenIcon } from '../../../assets/icons';


const Accordion = () => {
    return (
        <div>
            <div className="faq-toggle-wrapper">
                <CollapseSection
                    title={'Who will be my point of contact during the recruitment process?'}
                    iconOpen={() => <OpenIcon className="collapse-section__icon" />}
                >
                    <p>
                        You will be assigned a regional recruiter who specializes in your chosen service line.
                    </p>
                </CollapseSection>
            </div>
            <div className="faq-toggle-wrapper">
                <CollapseSection
                    title={'Once I begin performing work in one service line will I have the opportunity to expand into other service lines?'}
                    iconOpen={() => <OpenIcon className="collapse-section__icon" />}
                >
                    <p>
                        Yes. Once you have become familiar with our network and your performance meets established standards you will be provided multiple opportunities to expand.
                    </p>
                </CollapseSection>
            </div>
            <div className="faq-toggle-wrapper">
                <CollapseSection
                    title={'Why do I need a smart phone?'}
                    iconOpen={() => <OpenIcon className="collapse-section__icon" />}
                >
                    <p>
                        Smart phones are essential to operate Safeguard's web-based ordering system and utilize the work order-fulfilling software through an app downloaded to your smart phone.
                    </p>
                </CollapseSection>
            </div>
            <div className="faq-toggle-wrapper">
                <CollapseSection
                    title={'When will I receive my first work order?'}
                    iconOpen={() => <OpenIcon className="collapse-section__icon" />}
                >
                    <p>
                        New vendors must complete an on-boarding process which includes initial service line training online. Once the on-boarding process is complete you will enter a credentialing period, where you will receive your first work order.
                    </p>
                </CollapseSection>
            </div>
        </div>
    );
}

export default Accordion;

