import React, {Fragment, useState} from 'react';
import {connect} from 'react-redux';
import {ContinueButton, Form, FormChildProps, getIconColor, Password} from "../Form";
import {ApplicationState} from "../../store";
import {getLengthMaxMsg, getRequiredMsg} from "../../lib/helpers";
import {API} from "../../api";
import {CreatePassword} from "../../store/User";
import {PasswordIcon} from "../../assets/icons";
import {Message} from "../lib/Copy";

interface StateProps {
    isLoading?: boolean;
    email: string;
    hasErrors?: boolean;
}

interface DispatchProps {
    createAccountPassword: (values: CreatePassword) => void;
}

type Props = StateProps & DispatchProps;

class PasswordErrors {
    password?: string;
    confirmPassword?: string;
}

const CreatePasswordForm = (props: Props) => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const handleSubmit = (f: Props) => {
        props.createAccountPassword({email: props.email, password});
    };

    return (
        <div className="create-password__form">
            <Form
                noReload
                className="d-flex flex-column"
                onSubmit={handleSubmit}
                validate={(f: Props) => {
                    let errors: PasswordErrors = new PasswordErrors();
                    const passwordErrors: string[] = [];
                    const confirmPasswordErrors = [];
                    
                    const requiredMsg = getRequiredMsg(password, 'Password');
                    if (requiredMsg) {
                        passwordErrors.push(requiredMsg);
                    }
                    
                    const confirmRequiredMsg = getRequiredMsg(confirmPassword, 'Confirm Password');
                    if (confirmRequiredMsg) {
                        confirmPasswordErrors.push(confirmRequiredMsg);
                    }
                    
                    const passwordMaxLengthMsg = getLengthMaxMsg(password, 120, 'Password');
                    if (passwordMaxLengthMsg) {
                        passwordErrors.push(passwordMaxLengthMsg);
                    }
                    
                    if (password !== confirmPassword) {
                        passwordErrors.push('Password and Confirm Password do not match')
                    }

                    const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
                    if (!strongRegex.test(password)) {
                        passwordErrors.push('Password strength does not meet requirements')
                    }
                    
                    const firstPasswordError = passwordErrors[0];
                    const firstConfirmPasswordError = confirmPasswordErrors[0];
                    errors.password = firstPasswordError;
                    errors.confirmPassword = firstConfirmPasswordError;
                    return errors;
                }}
                values={{
                    password: '',
                    confirmPassword: ''
                }}
            >
                {
                    (formProps: FormChildProps) =>
                        <Fragment>
                            <Password
                                name="password"
                                className="mb-1"
                                placeHolder="Password"
                                onChange={v => setPassword(v)}
                                value={password}
                                validationMessage={formProps.errors.password}
                                prepend={(focused) =>
                                    <PasswordIcon
                                        color={getIconColor(focused, formProps.errors.password)}
                                    />
                                }
                                {...formProps}
                            />
                            <Message className="mb-15px" small>
                                Password must be at least 8 characters long and contain 1 lower case, upper case, number, and special character
                            </Message>
                            <Password
                                name="confirmPassword"
                                placeHolder="Confirm Password"
                                className="mb-30px"
                                onChange={v => setConfirmPassword(v)}
                                value={confirmPassword}
                                validationMessage={formProps.errors.confirmPassword}
                                prepend={(focused) =>
                                    <PasswordIcon
                                        color={getIconColor(focused, formProps.errors.confirmPassword)}
                                    />
                                }
                                {...formProps}
                            />
                            {
                                props.hasErrors && 
                                <p className="general-error">Could not create password.</p>
                            }
                            <ContinueButton/>
                        </Fragment>
                }
            </Form>
        </div>
    );
};

const mapStateToProps = (state: ApplicationState): StateProps => {
    if (!state.user) {
        return {
            isLoading: true, 
            hasErrors: false,
            email: '', 
        };
    }
    const hasErrors = state.api 
        && state.api.response 
        && state.api.response.errors
        && state.api.response.errors.length > 0;
    return {
        isLoading: state.api.isFetching,
        hasErrors: hasErrors,
        email: state.user.email || ''
    };
};

export default connect(
    mapStateToProps,
    {
        createAccountPassword: API.createAccountPassword
    }
)(CreatePasswordForm);
