import {Actions as UserActions, CreatePassword, UserState} from "./store/User";
import {makeApiAction} from "./store/api";
import {Field, Document} from "./store";

export const API = {
    getUser: () => makeApiAction(UserActions.GET_USER, 'GET', `user`),
    putField: (field: Field, getUserAfterCall?: boolean) => makeApiAction(UserActions.PUT_FIELD, 'PUT', 'user/field', field, true, getUserAfterCall),
    postDocument: (document: Document, cloudFileId?: any) => makeApiAction(UserActions.POST_DOCUMENT, 'POST', 'user/document/'+cloudFileId, document, true),
    deleteDocument: (id: string, documentType: string) => makeApiAction(UserActions.DELETE_DOCUMENT, 'DELETE', 'user/document', {
        id,
        type: documentType
    }, true),
    createAccount: (values: UserState) => makeApiAction(UserActions.POST_ACCOUNT, 'POST', 'account/create', values),
    createAccountPassword: (values: CreatePassword) => makeApiAction(UserActions.POST_ACCOUNT_PASSWORD, 'POST', 'account/create-password', values),
    logIn: (values: { email: string, password: string }) => makeApiAction(UserActions.POST_LOGIN, 'POST', 'account/login', values),
    forgotPassword: (email: string) => makeApiAction(UserActions.POST_FORGOT_PASSWORD, 'POST', 'account/forgot-password', {email}),
    postDocumentError: (document: Document) => makeApiAction(UserActions.POST_DOCUMENT, 'POST', 'user/document-error', document, true)
};

export const documentType = {
    photoId: 'PHOTOIDENTIFICATION',
    backgroundCheck: 'BACKGROUNDCHECK',
    insurance: 'GENEARALLIABILITYINSURANCE'
};

export interface DispatchProps {
    putField: (field: Field) => void;
    setField: (name: string, data?: any) => void;
}

