import React from 'react';
import { connect } from 'react-redux';
import { ActionCreators } from "../../store/User";
import { ApplicationState } from "../../store";
import { Routes } from "../../routes";
import { hasContent } from "../../lib/helpers";
import CompanyInfo from "./CompanyInfo";
import TypesOfWork from "./TypesOfWork";
import WorkLocations from "./WorkLocations";
import EmployerIdentification from "./EmployerIdentification";
import DashboardPageStepScaffold from '../lib/DashboardPageStepScaffold';

interface StateProps {
    isLoading: boolean;
}

interface DispatchProps {
    continueFromRoute: (currentRoute: string) => void;
    goToDashboard?: () => void;
}

type Props = StateProps & DispatchProps;

const CompanyInfoForm = (props: Props) => (
    <DashboardPageStepScaffold
        className="company-info"
        isLoading={props.isLoading}
        onContinue={() => props.continueFromRoute(Routes.companyInfo)}
    >
        <CompanyInfo />
        <TypesOfWork />
        <WorkLocations />
        <EmployerIdentification />
    </DashboardPageStepScaffold>
);

const mapStateToProps = (state: ApplicationState) => {
    if (state === undefined || state.user === undefined) {
        return { isLoading: true };
    }
    const user = state.user;
    return {
        isLoading: !hasContent(user.id)
    };
};

export default connect(
    mapStateToProps,
    {
        continueFromRoute: ActionCreators.continueFromRoute,
        goToDashboard: ActionCreators.goToDashboard
    }
)(CompanyInfoForm);
