import React from "react";
import {Col, Container, Row} from "reactstrap";
import singleVideo from "../../../assets/single_video.jpg";
import VideoWithOverlay from "../../lib/VideoWithOverlay";

interface OneColumnBlockProps {
    className?: string;
}

export default (props: OneColumnBlockProps) => {
    const videoUrl = "https://videos.sproutvideo.com/embed/1c9cdab41b11efc894/6fee4d0c60bc77f5?playerTheme=dark&amp;playerColor=2f3437&autoPlay=true";
    return (
        <Container className={props.className}>
            <Row>
                <Col sm="12">
                    <h2 className="one-col-block__title mb-4">
                        Become Part of an<br/> Elite Team Across the Nation
                    </h2>
                </Col>
                <Col sm={{offset: 2, size: 8}} className="mb-4">
                    <p className="one-col-block__msg">
                        Make your company part of an elite team that inspects,
                        maintains, and preserves homes across the nation.
                        Check out some testimonials from our contractors about
                        their experience partnering with Safeguard Properties.
                    </p>
                </Col>
                <Col
                    xs="12"
                    lg={{offset: 1, size: 10}}
                    className="text-center pointer d-flex justify-content-center"
                >
                    <VideoWithOverlay
                        videoImage={singleVideo}
                        elementClassName="one-col-block__video"
                        videoUrl={videoUrl}
                        showOverlay={true}
                    />
                </Col>
            </Row>
        </Container>
    );
}

