export const Routes = {
    home: '/',
    login: '/login',
    aboutUs: '/about-us',
    createPassword: '/create-password',
    dashboard: '/dashboard',
    backgroundCheck: '/background-check',
    companyInfo: '/company-info',
    identification: '/identification',
    insurance: '/insurance',
    terms: '/terms',
    faq: '/faq',
    privacyPolicy: '/privacy-policy',
    contactUs: '/contact-us',
    termsOfUse: '/terms-of-use',
    requestForgotPassword: '/request-forgot-password',
    forgotPassword: '/forgot-password',
    complete: '/complete'
};

export const ExternalLinks = {
    terms: 'https://safeguardproperties.com/wp-content/uploads/2022/07/Insurance-Requirements-10.19.21-002.pdf',
    insurance: 'https://safeguardproperties.com/wp-content/uploads/2022/07/Insurance-Requirements-10.19.21-002.pdf',
    identification: 'https://safeguardproperties.com/wp-content/uploads/2022/07/Insurance-Requirements-10.19.21-002.pdf',
}
