import React, { ReactNode, Fragment } from 'react';
import Container from 'reactstrap/lib/Container';
import { WithLoader } from '../WithLoader';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { ExpandedContinueButton } from '../../Form';
import "./styles.scss";
import classNames from 'classnames';

interface ScaffoldProps {
    isLoading?: boolean;
    className: string;
    children: ReactNode;
    hideButton?: boolean;
    continueTitle?: string;
    onContinue?: () => void;
    lg?: object;
    xl?: object;
}

export default (props: ScaffoldProps) => {
    const lg = props.lg || { offset: 1, size: 10 };
    const xl = props.xl || { offset: 2, size: 8 };
    return (
        <Container className={classNames("scaffold-container", props.className)}>
            <WithLoader isLoading={props.isLoading}>
                <Row className="scaffold-body">
                    <Col xs="12" lg={lg} xl={xl}>
                        {props.children}
                    </Col>
                </Row>
                {
                    !!props.onContinue
                        ? <ExpandedContinueButton
                            className="mt-25px"
                            onClick={props.onContinue}
                        >
                            {props.continueTitle}
                        </ExpandedContinueButton>
                        : <Fragment />
                }
            </WithLoader>
        </Container>
    );
};