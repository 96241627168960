import React, {ReactNode, useEffect, useState} from "react";
import BannerImage from "../../assets/banner_image.png";
import './styles.scss';
import classNames from "classnames";

interface BackgroundImageProps {
    className?: string;
    children?: ReactNode;
    absolute?: boolean;
    fullPageHeight?: boolean;
}

export const BackgroundImage = (props: BackgroundImageProps) => {
    const [scrollHeight, setScrollHeight] = useState(0);
    useEffect(() => {
        let scrollHeight = 0;
        const body = document.getElementById("body");
        if (body) {
            scrollHeight = body.scrollHeight;
            setScrollHeight(scrollHeight);
        }
    }, []);
    return (
        <div
            className={classNames("banner-image", props.className)}
            style={{
                backgroundImage: `url(${BannerImage})`,
                position: props.absolute ? 'absolute' : 'static',
                minHeight: !!props.fullPageHeight ? scrollHeight : "auto"
            }}
        >
            {props.children}
        </div>
    );
};
