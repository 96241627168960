import React, { useState } from "react";
import ReactSwipe from 'react-swipe';
import { Col, Container, Row } from "reactstrap";
import { ActiveDotIcon, DotIcon } from "../../../assets/icons";

export interface Testimonial {
    image: string;
    message: string;
    name: string;
    location: string;
    active?: boolean;
}

interface TestimonialsProps {
    testimonials: Testimonial[];
    className?: string;
}

export const Testimonials = (props: TestimonialsProps) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const getDot = (index: number) => (index === activeIndex
        ? <ActiveDotIcon color="#0B2B46" color2="#B1B3B6" />
        : <DotIcon color="#B1B3B6" />
    );

    return (
        <Container className={props.className}>
            <Row>
                <ReactSwipe
                    className="carousel"
                    swipeOptions={{
                        continuous: true,
                        startSlide: activeIndex,
                        transitionEnd: (index) => setActiveIndex(index)
                    }}
                >
                    {props.testimonials.map((testimonial, index) => TestimonialCard(testimonial, index))}
                </ReactSwipe>
                <div className="w-100 d-flex justify-content-center">
                    <div style={{ width: "175px" }} className="d-flex justify-content-between">
                        <button className="testimonial__dot" onClick={() => setActiveIndex(0)}>
                            {getDot(0)}
                        </button>
                        <button className="testimonial__dot" onClick={() => setActiveIndex(1)}>
                            {getDot(1)}
                        </button>
                        <button className="testimonial__dot" onClick={() => setActiveIndex(2)}>
                            {getDot(2)}
                        </button>
                    </div>
                </div>
            </Row>
        </Container>
    );
};

const TestimonialCard = (t: Testimonial, i: number) => (
    <div key={i}>
        <Col md={{ offset: 1, size: 10 }} className="text-center">
            <img
                alt="first testimonial"
                src={t.image}
                className="testimonial__image"
            />
            <h4 className="testimonial__message">
                {t.message}
            </h4>
            <hr className="testimonial__separator" />
        </Col>
        <Col xs="12" md={{ offset: 1, size: 10 }} className="text-center">
            <p className="testimonial__name">{t.name}</p>
            <p className="testimonial__location">{t.location}</p>
        </Col>
    </div>
);
