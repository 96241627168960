import React, { ReactNode, Fragment, useState } from "react";
import { CloseIcon, InfoIcon } from "../../../assets/icons";
import "./styles.scss";
import classNames from "classnames";

interface CollapseSectionProps {
    title: string;
    children: ReactNode;
    className?: string;
    iconOpen?: () => JSX.Element;
}

export function CollapseSection(props: CollapseSectionProps) {
    const [isCollapseOpen, setCollapseIsOpen] = useState(false);
    const openIcon = (props.iconOpen && props.iconOpen()) || <InfoIcon className="collapse-section__icon" />;
    return (
        <div className={classNames(props.className, "d-flex collapse-section")}>
            <div className="d-flex flex-row collapse-section__hoverable collapse-section__hoverable--item">
                <div className="d-flex flex-column">
                    {isCollapseOpen
                        ? <Fragment>
                            <span className="collapse-section__left-border collapse-section__hoverable--item" />
                        </Fragment>
                        : <Fragment>
                            <span className="collapse-section__left-border collapse-section__hoverable--item invisible" />
                        </Fragment>
                    }
                </div>
                <div className="d-flex flex-column">
                    <button
                        className={"d-flex justify-items-center px-0 collapse-section__hoverable collapse-section__hoverable--item"}
                        onClick={() => setCollapseIsOpen(!isCollapseOpen)}
                    >
                        {isCollapseOpen
                            ? <Fragment>
                                <CloseIcon className="collapse-section__icon" />
                            </Fragment>
                            : openIcon
                        }
                        <span className="collapse-section__title">
                            {props.title}
                        </span>
                    </button>
                    <div className="collapse-section__body d-flex">
                        {isCollapseOpen && props.children}
                    </div>
                </div>
            </div>
        </div>
    );
}

