import React, { useState } from 'react';
import { Col, Container, Row } from "reactstrap";
import classNames from "classnames";
import YouTube from 'react-youtube';
import maintenanceOverlay from '../../../assets/maintenance_overlay.png';
import inspectionOverlay from '../../../assets/inspection_overlay.png';
import grassAndSnowOverlay from '../../../assets/grass_and_snow_overlay.png';

interface VideosProps {
    className?: string;
}

const videos = [
    {
        video: {
            id: 'x5SMqHsKG3A',
            overlay: grassAndSnowOverlay
        },
        title: 'Grass & Snow',
        msg: 'Proper exterior maintenance improves curb appeal, maintains a  safe environment, and assures compliance with local ordinances.'
    }, {
        video: {
            id: 'edeu7v6CP8Y',
            overlay: inspectionOverlay
        },
        title: 'Inspections',
        msg: 'Property inspections identify issues and damages that threaten the value, condition and safety of properties.'
    }, {
        video: {
            id: 'KmaDbaljeOA',
            overlay: maintenanceOverlay
        },
        title: 'Maintenance',
        msg: 'Protect and preserve vacant properties while keeping them maintained and secure.'
    }
];

export default (props: VideosProps) => {
    return (
        <Container className={props.className}>
            <BlockHeader />
            <Separator />
            <Row className="text-center">
                {
                    videos.map(
                        (v, i) =>
                            <VideoCard
                                key={i}
                                className={classNames("mt-xl-3", i > 0 ? "mt-5" : "mt-3")}
                                detail={v}
                            />
                    )
                }
            </Row>
        </Container>
    );
};

interface Video {
    id: string;
    overlay: any;
}

interface VideoCardDetail {
    video: Video;
    title: string;
    msg: string;
}

interface VideoCardProps {
    className?: string;
    detail: VideoCardDetail;
}

const VideoCard = (props: VideoCardProps) => {
    const [clicked, setClick] = useState(false);
    return (
        <Col xs="12" xl="4">
            <div className={classNames("video-card__container", "mx-auto", props.className)}>
                <div
                    onClick={() => setClick(!clicked)}
                    className="video-card__video"
                >
                    <img
                        style={{ objectFit: "cover", width: "100%", height: "100%" }}
                        className={classNames(clicked ? "hide" : "block", "pointer")}
                        alt={props.detail.title}
                        src={props.detail.video.overlay}
                    />
                    <YouTube
                        opts={{
                            height: "275px",
                        }}
                        containerClassName={clicked ? "block" : "hide"}
                        videoId={clicked ? props.detail.video.id : ""}
                    />
                </div>
                <div className="video-card__footer">
                    <div className="video-card__msg-box">
                        <div className="video-card__msg-box--header d-flex">
                            <h5 className="align-self-center w-100">
                                {props.detail.title}
                            </h5>
                        </div>
                        <hr className="video-card__separator" />
                        <p className="video-card__msg-box--msg">
                            {props.detail.msg}
                        </p>
                    </div>
                </div>
            </div>
        </Col>
    );
};

const BlockHeader = () => (
    <Row>
        <Col xs="12" className="text-center">
            <h2 className="videos__header">What are Mortgage Field Services</h2>
        </Col>
    </Row>
);

const Separator = () => (
    <Row>
        <Col xs="12" className="text-center ml-0">
            <hr className="videos__separator" />
        </Col>
    </Row>
);
