import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Routes } from "../../routes";
import { Col, Container, Row } from "reactstrap";
import { WithLoader } from "../lib/WithLoader";
import { ApplicationState } from "../../store";
import { hasContent } from "../../lib/helpers";
import { didUserAcceptTerms, UserState, isAtLeastOneSteComplete, areAllStepsComplete, isCompanyInfoComplete, isCompanyInfoInProgress, isPhotoIdComplete, isBackgroundCheckComplete, isInsuranceComplete } from "../../store/User";
import { DashboardLink, DashboardLinkProps, LinkState } from "./DashboardLink";

interface StateProps {
    isLoading: boolean;
    companyInfoLinkState: LinkState;
    noStepsComplete?: boolean,
    atLeastOneStepComplete?: boolean,
    allStepsComplete?: boolean,
    links: DashboardLinkProps[];
}

type Props = StateProps;

const DashboardPage = (props: Props) => {
    return (
        <div className="dashboard__wrapper flex-fill">
            <div className="h-100 d-flex flex-column justify-content-center">
                <Container className="dashboard d-flex flex-column justify-content-center">
                    <Row className="dashboard__row">
                        <Col md="4" className="px-0">
                            <DashboardBanner {...props} />
                        </Col>
                        <Col md="8" className="dashboard__links pr-0">
                            <WithLoader isLoading={props.isLoading}>
                                <div className="d-flex flex-column w-100 pt-4">
                                    {props.links.map((p, i) => <DashboardLink key={i} {...p} />)}
                                </div>
                            </WithLoader>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
};

const DashboardBanner = (props: Props) => {
    const noCompleteStepsBanner = <Fragment>
            <h1>Welcome to the Vendor dashboard</h1>
            <p>Complete the following information to become a Safeguard contractor.</p>
    </Fragment>
    const atLeastOneCompleteStepBanner = <Fragment>
            <h1 className="roboto-fi-bug">You have unfinished tasks</h1>
            <p>Complete the following information to become a Safeguard contractor.</p>
    </Fragment>
    const allStepsCompleteBanner = <Fragment>
            <h1>Thank you for completing the Safeguard vendor application</h1>
            <p>We are reviewing your information and will be in touch with next steps.</p>
    </Fragment>

    return (
        <div className="dashboard__banner h-100 w-100 px-4">
            {
                props.noStepsComplete
                    ? noCompleteStepsBanner
                    : props.atLeastOneStepComplete
                        ? atLeastOneCompleteStepBanner
                        : allStepsCompleteBanner
            }
        </div>
    );
}

export default connect(
    (state: ApplicationState) => {
        if (!state || !state.user) {
            return {
                isLoading: true,
                noStepsComplete: true,
                atLeastOneStepComplete: false,
                allStepsComplete: false,
                links: [] as DashboardLinkProps[]
            }
        }
        const user = state.user;
        return {
            isLoading: !hasContent(user.email),
            noStepsComplete: !isAtLeastOneSteComplete(user) && !areAllStepsComplete(user),
            atLeastOneStepComplete: isAtLeastOneSteComplete(user) && !areAllStepsComplete(user),
            allStepsComplete: areAllStepsComplete(user),
            links: getDashboardLinks(state.user)
        }
    }
)(DashboardPage);


const getDashboardLinks = (user: UserState) => (
    [{
        route: Routes.companyInfo,
        title: 'Company Info',
        state: isCompanyInfoComplete(user)
            ? LinkState.Complete
            : isCompanyInfoInProgress(user)
                ? LinkState.InProgress
                : LinkState.Unknown,
    }, {
        route: Routes.identification,
        title: 'Identification',
        state: user.photoIdUploadFailed
            ? LinkState.Failed
            : isPhotoIdComplete(user)
                ? LinkState.Complete
                : LinkState.Unknown
    }, {
        route: Routes.backgroundCheck,
        title: 'Background Check',
        state: user.backgroundCheckUploadFailed
            ? LinkState.Failed
            : isBackgroundCheckComplete(user)
                ? LinkState.Complete
                : LinkState.Unknown
    }, {
        route: Routes.insurance,
        title: 'Insurance',
        state: user.insuranceUploadFailed
            ? LinkState.Failed
            : isInsuranceComplete(user)
                ? LinkState.Complete
                : LinkState.Unknown
    }, {
        route: Routes.terms,
        title: 'Terms',
        state: didUserAcceptTerms(user)
            ? LinkState.Complete
            : LinkState.Unknown
    },
    ] as DashboardLinkProps[]
);

