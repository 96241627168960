import React, {useState, useEffect} from 'react';
import {Col, Row} from "reactstrap";
import {connect} from 'react-redux';
import "./styles.scss";
import {TextBox} from "../../Form";
import {
    ActionCreators,
    UserFields
} from "../../../store/User";
import {ApplicationState} from "../../../store";
import {API, DispatchProps} from "../../../api";
import {CollapseSection} from "../../lib/CollapseSection";
import {Title} from '../../lib/Copy';

interface StateProps {
    ein?: string;
}

type Props = StateProps & DispatchProps;

const EmployerIdentification = (props: Props) => {
    const [state, setState] = useState({...props});
    useEffect(() => setState(props), [props]);
    const setEin = (data: string) => setState({...state, ein: formatEin(data)});

    return (
        <div className="employer-identification-section">
            <Row>
                <Col xs="12">
                    <Title>Employer Identification Number (Optional)</Title>
                </Col>
            </Row>
            <CollapseSection title={'Why do I need to provide my EIN?'}>
                <p>
                    Your EIN is an individually assigned number by the IRS and 
                    used to determine federal tax requirements. 
                    Safeguard Properties will not share your EIN.
                </p>
            </CollapseSection>
            <Row>
                <Col xs="12" md="3">
                    <TextBox
                        name="ein"
                        className="mt-30px"
                        onChange={setEin}
                        onBlur={ein => checkSet(UserFields.EIN, ein, props)}
                        value={state.ein}
                        label="EIN"
                        placeHolder="XX-XXXXXXX"
                    />
                </Col>
            </Row>
        </div>
    );
};

const mapStateToProps = (state: ApplicationState) => {
    if (state === undefined || state.user === undefined) {
        return {ein: ''};
    }
    const user = state.user;
    return {
        ein: user.ein || ''
    };
};

export default connect(
    mapStateToProps,
    {
        setField: ActionCreators.setField,
        putField: API.putField,
    }
)(EmployerIdentification);

const checkSet = (
    name: string,
    data: string,
    props: any
) => {
    if (props[name] !== data) {
        props.setField(name, data);
        props.putField({name, data});
    }
};

const formatEin = (value: string): string => {
    let input = value.replace('-', '');
    if (input.length > 9) return value.substr(0, 10);
    if (!isNaN(Number(input))) {
        let value = input.substr(0, 2);
        if (input.length > 2) {
            value += '-' + input.substr(2);
        }
        return value;
    }
    return value.substr(0, value.length - 1);
};
