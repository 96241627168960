import React from 'react';
import {Row, Col} from "reactstrap";
import CreatePasswordForm from "./CreatePasswordForm";
import {connect} from "react-redux";
import {ApplicationState} from "../../store";
import SimplePageScaffold from "../lib/SimplePageScaffold";
import {WithLoader} from "../lib/WithLoader";

interface StateProps {
    firstName?: string;
    isLoading: boolean;
}

type Props = StateProps;

const capitalize = (v: string) => v[0].toUpperCase() + v.substr(1, v.length);

const CreatePasswordPage = (props: Props) => {
    const getTitle = (props: Props) => {
        const firstName = props.firstName;
        if (firstName) {
            return `${capitalize(firstName)}, You're In!`
        }
        return '';
    };
    return (
        <SimplePageScaffold className="create-password py-5">
            <WithLoader
                isLoading={props.isLoading}
                style={{minHeight: "425px", height: "425px"}}
            >
                <Row>
                    <Col xs={{offset: 1, size: 10}}>
                        <h1 className="text-center mb-4">{getTitle(props)}</h1>
                        <p className="simple-page-scaffold__msg text-center mb-3">
                            Create a password so you can log back in to your vendor profile.
                        </p>
                        <p className="simple-page-scaffold__msg2 text-center mb-5">
                            Your username will be the email address you provided at signup.
                        </p>
                    </Col>
                    <Col xs={{offset: 1, size: 10}} sm={{offset: 2, size: 8}}>
                        <CreatePasswordForm/>
                    </Col>
                    <Pixel />
                </Row>
            </WithLoader>
        </SimplePageScaffold>
    );
};

const Pixel = () => (
    <img src="https://track.ziprecruiter.com/conversion?enc_account_id=787f5f4d" width="1" height="1" alt="pixel"/>
);


export default connect(
    (state: ApplicationState) => {
        if (!state.user) {
            return {firstName: '', isLoading: true}
        }
        return {
            firstName: state.user.firstName,
            isLoading: state.api.isFetching
        }
    }
)(CreatePasswordPage);
