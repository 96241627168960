import React, {useEffect} from 'react';
import {Title} from "../Text";
import CreatePasswordForm from "../CreatePasswordPage/CreatePasswordForm";
import {RouteComponentProps} from "react-router";
import {setForgotPasswordTempToken} from "../../sagas/login";
import {isJwtExpired} from "../../lib/helpers";
import {connect} from "react-redux";
import {ActionCreator} from '../../store/messages';
import {Col, Row} from "reactstrap";
import SimplePageScaffold from "../lib/SimplePageScaffold";
import { WithLoader } from '../lib/WithLoader';
import { ApplicationState } from '../../store';

interface MatchParams {
    token: string;
}

interface ForgotPasswordProps extends RouteComponentProps<MatchParams> {
    isLoading?: boolean;
}

interface DispatchProps {
    setTokenExpired: (isExpired: boolean) => void;
}

type Props = ForgotPasswordProps & DispatchProps;

const ForgotPassword = (props: Props) => {
    useEffect(() => {
        const token = props.match.params.token;
        if (isJwtExpired(token)) {
            props.setTokenExpired(true);
        } else {
            setForgotPasswordTempToken(token);
        }
    }, [props]);
    return (
        <SimplePageScaffold className="forgot-password">
            <Row className="forgot-password__form-wrapper w-100 py-5">
                <Col xs="12">
                    <Title text="Reset Password"/>
                </Col>
                <Col sm={{ offset: 2, size: 8 }} xs="12" className="mt-30px">
                    <WithLoader isLoading={props.isLoading} style={{minHeight: "210px", height: "210px"}}>
                        <CreatePasswordForm/>
                    </WithLoader>
                </Col>
            </Row>
        </SimplePageScaffold>
    );
};

export default connect(
    (state: ApplicationState) => {
        if (state === undefined || state === undefined) {
            return {
                isLoading: false
            };
        }

        return {
            isLoading: state.api.isFetching
        };
    },
    {
        setTokenExpired: ActionCreator.setTokenExpired
    }
)(ForgotPassword);
