import React, {Fragment, useState, useEffect} from 'react';
import {connect} from 'react-redux';
import "./styles.scss";
import {MessageBox} from "../../Form";
import CheckBox from "../../Form/CheckBox";
import {Col, Label, Row} from "reactstrap";
import {
    ActionCreators,
    UserFields
} from "../../../store/User";
import {ApplicationState} from "../../../store";
import {API, DispatchProps} from "../../../api";
import {hasContent, stringLength} from "../../../lib/helpers";
import {Title, Message} from '../../lib/Copy';

interface StateProps {
    workLocationRequests: string;
}

type Props = StateProps & DispatchProps;

// note: this value is saved if the user clicks yes, but doesn't fill out the message box
const implicitYesToOtherLocations = "YES_TO_OTHER_LOCATIONS";

const WorkLocations = (props: Props) => {
    const [state, setState] = useState({
        ...props,
        workInOtherLocations: hasContent(props.workLocationRequests)
    });
    useEffect(() => setState({
        ...props,
        workInOtherLocations: hasContent(props.workLocationRequests)
    }), [props]);

    return (
        <div className="work-locations-section">
            <Row className="pb-30px">
                <Col xs="12">
                    <Title>Work Locations</Title>
                    <Message>
                        Are you interested in working in locations outside of your ZIP code?
                    </Message>
                </Col>
            </Row>
            <Row>
                <Col xs="12">
                    <CheckBox
                        id="workInOtherLocations"
                        className="pb-15px"
                        checked={state.workInOtherLocations}
                        onChange={() => {
                            // state.workInOtherLocations is the past state of the checkbox since we haven't called
                            // setState() yet
                            if (state.workInOtherLocations) {
                                // if we were previously checked, but now are not, then clear their selection
                                // @tech-debt: make the call a bit later to lower the possibility of the delete 
                                // getting to the api first. We really should buffer and check the open promises
                                setTimeout(() => checkSet(UserFields.WORK_LOCATION_REQUESTS, "", props), 1000);
                            }
                            if (!state.workInOtherLocations) {
                                // if we were previously not checked, but now are, then set the default value
                                checkSet(UserFields.WORK_LOCATION_REQUESTS, implicitYesToOtherLocations, props);
                            }
                            setState({...state, workInOtherLocations: !state.workInOtherLocations});
                        }}
                    >
                        I want to do work in other locations
                    </CheckBox>

                    {
                        state.workInOtherLocations
                            ? <Fragment>
                                <MessageBox
                                    name="workLocationRequests"
                                    placeHolder="Locations"
                                    onChange={workLocationRequests => {
                                        if (workLocationRequests.length <= 255) {
                                            setState({
                                                ...state,
                                                workLocationRequests
                                            })
                                        }
                                    }}
                                    onBlur={workLocationRequests => {
                                        if (state.workInOtherLocations) {
                                            return hasContent(workLocationRequests)
                                                ? checkSet(UserFields.WORK_LOCATION_REQUESTS, workLocationRequests, props)
                                                : checkSet(UserFields.WORK_LOCATION_REQUESTS, implicitYesToOtherLocations, props);
                                        }
                                        return checkSet(UserFields.WORK_LOCATION_REQUESTS, "", props);
                                    }}
                                    value={
                                        hasContent(state.workLocationRequests)
                                        && state.workLocationRequests !== implicitYesToOtherLocations
                                            ? state.workLocationRequests
                                            : ''
                                    }
                                />
                                <Label
                                    className="d-flex flex-row-reverse"
                                    style={{fontSize: '12px'}}
                                >
                                    {state.workLocationRequests !== implicitYesToOtherLocations ? stringLength(state.workLocationRequests) : 0} /
                                    255
                                </Label>
                            </Fragment>
                            : <Fragment/>
                    }
                </Col>
            </Row>
        </div>
    );
};

const mapStateToProps = (state: ApplicationState) => {
    if (state === undefined || state.user === undefined) {
        return {workLocationRequests: ''};
    }
    const user = state.user;
    return {
        workLocationRequests: user.workLocationRequests || ''
    };
};

export default connect(
    mapStateToProps,
    {
        setField: ActionCreators.setField,
        putField: API.putField,
    }
)(WorkLocations);

const checkSet = (
    name: string,
    data: string,
    props: any
) => {
    if (props[name] !== data) {
        props.setField(name, data);
        props.putField({name, data});
    }
};

