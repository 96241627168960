import * as User from './User';
import {ApiState, apiReducer} from './api';
import {documentUploadReducer, DocumentUploadState} from "./documentUpload";
import {messagesReducer, MessagesState} from "./messages";

// The top-level state object
export interface ApplicationState {
    user: User.UserState | undefined;
    api: ApiState,
    documentUpload: DocumentUploadState,
    messages: MessagesState
}

export interface GenericAction {
    type: string,
    payload: any
}

export interface ContinueFromRoute {
    type: string,
    payload: {
        currentRoute: string
    }
}

export interface FieldAction {
    type: string,
    payload: {
        name: string,
        data?: any
    }
}

export interface Field {
    name: string,
    data?: any
}

export interface Document {
    id: string,
    name: string,
    size: number,
    mimeType: string,
    type: 'ID' | 'BACKGROUND_CHECK' | 'INSURANCE'
    data?: any
}

export function createSetActionType(name: string): string {
    return `SET_${name}`.toUpperCase()
}

export function createSetField(name: string, data?: any): FieldAction {
    return ({
        type: createSetActionType(name),
        payload: {
            name,
            data
        }
    });
}

export function createGenericAction(type: string, name: string, data?: any): FieldAction {
    return ({
        type,
        payload: {
            name,
            data
        }
    });
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    user: User.reducer,
    api: apiReducer,
    documentUpload: documentUploadReducer,
    messages: messagesReducer
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
