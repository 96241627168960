import React, { ReactNode } from 'react';
import { CaretIcon } from '../../../assets/icons';
import classNames from 'classnames';
import "./styles.scss";

interface ExternalLinkProps {
    to: string;
    className?: string;
    withCaret?: boolean;
    children: ReactNode;
}

export default (props: ExternalLinkProps) => (
    <a
        href={props.to}
        target="_blank"
        rel="noopener noreferrer"
        className={classNames("external-link", props.className)}
    >
        {props.children}
        {
            !!props.withCaret
            && <CaretIcon color="#8DBAE6" className="external-link__caret-icon" />
        }
    </a>
)