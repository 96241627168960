import React, {useState} from 'react';
import RequestForgotPasswordForm from "./RequestForgotPasswordForm";
import {Col, Row} from "reactstrap";
import SimplePageScaffold from "../lib/SimplePageScaffold";

export default () => {
    const [emailSent, setEmailSent] = useState(false);
    return (
        <SimplePageScaffold className="request-forgot-password py-5">
            <Row className="w-100">
                <Col xs={{size: 10, offset: 1}}>
                    <h1 className="text-center mb-3">Forgot Password</h1>
                    <p className="simple-page-scaffold__msg text-center mb-5">We'll send you an email with a link to
                        reset your password.</p>
                </Col>
                <Col sm={{ offset: 2, size: 8 }} xs="12">
                    {!emailSent
                        ? <RequestForgotPasswordForm emailSent={sent => setEmailSent(sent)}/>
                        : renderEmailSent()
                    }
                </Col>
            </Row>
        </SimplePageScaffold>
    );
};

const renderEmailSent = () => {
    return (
        <div>
            <p className="simple-page-scaffold__msg text-center">
                Email Sent
            </p>
        </div>
    );
};