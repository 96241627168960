import React from "react";
import {Container} from "reactstrap";
import SignUpForm from "../SignUpForm";
import {BackgroundImage} from "../../BackgroundImage";
import VideoWithOverlay from "../../lib/VideoWithOverlay";
import singleVideo from "../../../assets/single_video.jpg";


export default () => {
    const videoUrl = "https://www.youtube.com/embed/4fIAL2dd1Pc?&autoplay=1";
    return (
        <div id="home__banner-wrapper">
            <Container>
                <BackgroundImage absolute/>
                <div
                    className="home__banner d-flex align-items-center justify-content-center">
                    <div
                        className="home__banner-inner-wrapper d-flex flex-column flex-wrap justify-content-center align-items-center align-items-lg-start">
                        <div className="home__banner-msg">
                            <h1 className="home__banner-title text-shadow">
                                Do Work That Matters
                            </h1>
                            <p className="home__banner-sub-msg text-shadow">
                                Join Safeguard's elite contractor network and be your own boss, grow your business, make
                                more
                                money, all while helping your community.
                            </p>
                        </div>
                        <VideoWithOverlay
                            videoImage={singleVideo}
                            className="order-lg-2 order-3"
                            elementClassName="home__banner-video"
                            videoUrl={videoUrl}
                            showOverlay={true}
                        />
                        <div className="order-lg-3 order-2">
                            <SignUpForm/>
                        </div>
                    </div>
                </div>
            </Container>
            <NavMenuTriggerScrollRegion/>
        </div>
    );
};

const NavMenuTriggerScrollRegion = () => (
    <div
        id="home__banner-end"
        className="mt-50px"
        style={{
            minHeight: "1px",
            width: "100%"
        }}/>
);