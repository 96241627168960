import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from "reactstrap";
import { SelectList, TextBox } from "../../Form";
import {
    ActionCreators,
    UserFields
} from "../../../store/User";
import { ApplicationState } from "../../../store";
import { API, DispatchProps } from "../../../api";
import { hasContent, getLengthMaxMsg } from "../../../lib/helpers";
import "./styles.scss";
import { Title, Message } from '../../lib/Copy';

interface StateProps {
    isLoading: boolean;
    addressLine1?: string;
    addressLine2?: string;
    city?: string;
    state?: string;
    zip?: string;
    referral?: string;
    company?: string;
    titleInCompany?: string;
}

type Props = StateProps & DispatchProps;

const CompanyInfo = (props: Props) => {
    const [state, setState] = useState(props);
    useEffect(() => setState(props), [props]);

    return (
        <div className="company-info-section">
            <Row>
                <Col xs="12" className="company-info-section__header">
                    <Title>Company Information</Title>
                    <Message>
                        Please provide your company address.
                        Dont have a company address?
                        You can enter your home address for now.
                    </Message>
                </Col>
            </Row>
            <Row>
                <Col md="6" xs="12" className="pt-30px">
                    <TextBox
                        name="addressLine1"
                        onChange={addressLine1 => {
                            if (addressLine1.length <= 255) {
                                setState({ ...state, addressLine1 });
                            }
                        }}
                        onBlur={addressLine1 => checkSet(UserFields.ADDRESS_LINE1, addressLine1, props)}
                        placeHolder="Street Address"
                        label="Address Line 1*"
                        value={state.addressLine1}
                        validate={v => getLengthMaxMsg(v || '', 255, "Address Line 1")}
                    />
                </Col>
                <Col md="6" xs="12" className="pt-30px">
                    <TextBox
                        name="addressLine2"
                        onChange={addressLine2 => {
                            if (addressLine2.length <= 255) {
                                setState({ ...state, addressLine2 });
                            }
                        }}
                        onBlur={addressLine2 => checkSet(UserFields.ADDRESS_LINE2, addressLine2, props)}
                        placeHolder="Suite, Apt, etc."
                        label="Address Line 2"
                        value={state.addressLine2}
                        validate={v => getLengthMaxMsg(v || '', 255, "Address Line 2")}
                    />
                </Col>
            </Row>

            <Row>
                <Col md="6" xs="12" className="pt-30px">
                    <TextBox
                        name="city"
                        onChange={city => {
                            if (city.length <= 100) {
                                setState({ ...state, city })
                            }
                        }}
                        onBlur={city => checkSet(UserFields.CITY, city, props)}
                        placeHolder="City"
                        label="City*"
                        value={state.city}
                        validate={v => getLengthMaxMsg(v || '', 100, "City")}
                    />
                </Col>
                <Col md="3" xs="12" className="pt-30px">
                    <SelectList
                        name="state"
                        onChange={state_ => {
                            setState({ ...state, state: state_ });
                            checkSet(UserFields.STATE, state_, props);
                        }}
                        placeHolder="State"
                        list={states}
                        label="State*"
                        value={state.state}
                    />
                </Col>
                <Col md="3" xs="12" className="pt-30px">
                    <TextBox
                        name="zip"
                        onChange={zip => {
                            if (!isNaN(Number(zip)) && zip.length <= 5) {
                                setState({ ...state, zip })
                            }
                        }}
                        onBlur={zip => checkSet(UserFields.ZIP, zip, props)}
                        placeHolder="ZIP"
                        validationMessage="Zip is required"
                        label="ZIP*"
                        value={state.zip}
                        validate={v => getLengthMaxMsg(v || '', 5, "ZIP")}
                    />
                </Col>
            </Row>

            <Row>
                <Col xs="12" md='6' className="pt-30px">
                    <TextBox
                        name="company"
                        onChange={company => {
                            if (company.length <= 99) {
                                setState({ ...state, company });
                            }
                        }}
                        onBlur={companyName => checkSet(UserFields.COMPANY, companyName, props)}
                        placeHolder="Company Name"
                        label="Company Name"
                        value={state.company}
                        validate={v => getLengthMaxMsg(v || '', 99, "Company Name")}
                    />
                </Col>

                <Col xs="12" md='6' className="pt-30px">
                    <TextBox
                        name="titleInCompany"
                        onChange={titleInCompany => {
                            if (titleInCompany.length <= 99) {
                                setState({ ...state, titleInCompany });
                            }
                        }}
                        onBlur={titleInCompany => checkSet(UserFields.TITLE_IN_COMPANY, titleInCompany, props)}
                        placeHolder="Your Title / Role in Company"
                        label="Your Title / Role in Company"
                        value={state.titleInCompany}
                        validate={v => getLengthMaxMsg(v || '', 99, "Your Title / Role in Company")}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs="12" className="pt-30px">
                    <SelectList
                        name="referral"
                        onChange={referral => {
                            setState({ ...state, referral });
                            checkSet(UserFields.REFERRAL, referral, props);
                        }}
                        list={referrals}
                        label="How did you hear about us?*"
                        value={state.referral}
                    />
                </Col>
            </Row>
        </div>
    );
};

const mapStateToProps = (state: ApplicationState) => {
    if (state === undefined || state.user === undefined) {
        return { isLoading: true };
    }
    const user = state.user;
    return {
        isLoading: !hasContent(user.id),
        addressLine1: user.addressLine1 || '',
        addressLine2: user.addressLine2 || '',
        city: user.city || '',
        zip: user.zip || '',
        state: user.state || '',
        referral: user.referral || '',
        company: user.company || '',
        titleInCompany: user.titleInCompany || '',
    };
};

export default connect(
    mapStateToProps,
    {
        setField: ActionCreators.setField,
        putField: API.putField
    }
)(CompanyInfo);

const checkSet = (
    name: string,
    data: string,
    props: any
) => {
    if (props[name] !== data) {
        props.setField(name, data);
        props.putField({ name, data });
    }
};

const referrals = [
    { name: "Select", value: "" },
    { name: "Craigslist", value: "craiglist" },
    { name: "Indeed", value: "indeed" },
    { name: "Facebook", value: "facebook" },
    { name: "Google/Internet Search", value: "internet_search" },
    { name: "LinkedIn", value: "linkedin" },
    { name: "Glassdoor", value: "glassdoor" },
    { name: "Previous Vendor", value: "previous_vendor" },
    { name: "Zip Recruiter", value: "zip_recruiter" },
];

const states = [
    { name: "Select", value: "" },
    { name: "Alabama", value: "AL" },
    { name: "Alaska", value: "AK" },
    { name: "Arizona", value: "AZ" },
    { name: "Arkansas", value: "AR" },
    { name: "California", value: "CA" },
    { name: "Colorado", value: "CO" },
    { name: "Connecticut", value: "CT" },
    { name: "Delaware", value: "DE" },
    { name: "District Of Columbia", value: "DC" },
    { name: "Florida", value: "FL" },
    { name: "Georgia", value: "GA" },
    { name: "Guam", value: "GU" },
    { name: "Hawaii", value: "HI" },
    { name: "Idaho", value: "ID" },
    { name: "Illinois", value: "IL" },
    { name: "Indiana", value: "IN" },
    { name: "Iowa", value: "IA" },
    { name: "Kansas", value: "KS" },
    { name: "Kentucky", value: "KY" },
    { name: "Louisiana", value: "LA" },
    { name: "Maine", value: "ME" },
    { name: "Maryland", value: "MD" },
    { name: "Massachusetts", value: "MA" },
    { name: "Michigan", value: "MI" },
    { name: "Minnesota", value: "MN" },
    { name: "Mississippi", value: "MS" },
    { name: "Missouri", value: "MO" },
    { name: "Montana", value: "MT" },
    { name: "Nebraska", value: "NE" },
    { name: "Nevada", value: "NV" },
    { name: "New Hampshire", value: "NH" },
    { name: "New Jersey", value: "NJ" },
    { name: "New Mexico", value: "NM" },
    { name: "New York", value: "NY" },
    { name: "North Carolina", value: "NC" },
    { name: "North Dakota", value: "ND" },
    { name: "Ohio", value: "OH" },
    { name: "Oklahoma", value: "OK" },
    { name: "Oregon", value: "OR" },
    { name: "Pennsylvania", value: "PA" },
    { name: "Puerto Rico", value: "PR" },
    { name: "Rhode Island", value: "RI" },
    { name: "South Carolina", value: "SC" },
    { name: "South Dakota", value: "SD" },
    { name: "Tennessee", value: "TN" },
    { name: "Texas", value: "TX" },
    { name: "Utah", value: "UT" },
    { name: "Vermont", value: "VT" },
    { name: "Virgin Islands", value: "VI" },
    { name: "Virginia", value: "VA" },
    { name: "Washington", value: "WA" },
    { name: "West Virginia", value: "WV" },
    { name: "Wisconsin", value: "WI" },
    { name: "Wyoming", value: "WY" }
];

