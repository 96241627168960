import React, {Fragment, useState} from 'react';
import {connect} from 'react-redux';
import {Form, FormChildProps, TextBox, Telephone, Email, ContinueButton, getIconColor, SelectList} from "../../Form";
import {ApplicationState} from "../../../store";
import {ActionCreators, UserFields, UserState} from "../../../store/User";
import {getLengthExactMsg, getLengthMaxMsg, getRequiredMsg} from "../../../lib/helpers";
import {API} from "../../../api";
import {getApiError} from "../../../store/helpers";
import {Link} from "react-router-dom";
import {Routes} from "../../../routes";
import {CaretIcon, KabobIcon, MailIcon, PersonIcon, PhoneIcon, ZipCodeIcon} from "../../../assets/icons";
import {WithLoader} from "../../lib/WithLoader";

type StateProps = {
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    zip: string,
    serviceLine: string;
    isLoading?: boolean;
    externalLeadRequest: string,
    errorMessage?: string;
}

interface DispatchProps {
    setField: (name: string, data?: any) => void;
    createAccount: (values: UserState) => void;
}

type Props = StateProps & DispatchProps;

class SignUpErrors {
    firstName?: string;
    lastName?: string;
    email?: string;
    phone?: string;
    zip?: string;
    serviceLine?: string;
}

const lightBlue = "#81B4DF";

const SignUpForm = (props: Props) => {
    const [state, setState] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        zip: '',
        serviceLine: '',
    });

    const handleSubmit = (f: Props) => {
        props.createAccount({...f, externalLeadRequest: props.externalLeadRequest});
    };

    return (
        <div className="sign-up-form gray-border">
            <h2 className="sign-up-form__title mx-auto mb-4">
                {
                    props.isLoading ? "Creating Account" : "Become a Safeguard Contractor"
                }
            </h2>
            <WithLoader
                isLoading={props.isLoading}
                style={{minHeight: "376px", height: "376px"}}
            >
                <Form
                    noReload
                    className="d-flex flex-column"
                    onSubmit={handleSubmit}
                    validate={(f: Props) => {
                        let errors: SignUpErrors = new SignUpErrors();
                        errors.firstName = getRequiredMsg(f.firstName, 'First Name');
                        errors.lastName = getRequiredMsg(f.lastName, 'Last Name');
                        errors.email = getRequiredMsg(f.email, 'Email');
                        errors.phone = getRequiredMsg(f.phone, 'Phone');
                        errors.zip = getRequiredMsg(f.zip, 'ZIP');
                        errors.serviceLine = getRequiredMsg(f.serviceLine, 'Service Line');

                        if (!errors.firstName) {
                            errors.firstName = getLengthMaxMsg(f.firstName!, 120, 'First Name');
                        }
                        if (!errors.lastName) {
                            errors.lastName = getLengthMaxMsg(f.lastName!, 120, 'Last Name');
                        }
                        if (!errors.email) {
                            errors.email = getLengthMaxMsg(f.email!, 255, 'Email');
                        }
                        if (!errors.phone) {
                            errors.phone = getLengthExactMsg(f.phone!, 10, 'Phone');
                        }
                        if (!errors.zip) {
                            errors.zip = getLengthExactMsg(f.zip!, 5, 'ZIP');
                        }
                        return errors;
                    }}
                    values={{
                        firstName: state.firstName,
                        lastName: state.lastName,
                        email: state.email,
                        phone: state.phone,
                        zip: state.zip,
                        serviceLine: state.serviceLine
                    }}
                >
                    {
                        (formProps: FormChildProps) =>
                            <Fragment>
                                <TextBox
                                    name="firstName"
                                    className="mb-15px"
                                    onChange={firstName => setState({...state, firstName})}
                                    onBlur={firstName => props.setField(UserFields.FIRST_NAME, firstName)}
                                    placeHolder="First Name"
                                    value={state.firstName}
                                    validationMessage={formProps.errors.firstName}
                                    prepend={(focused) => <PersonIcon
                                        color={getIconColor(focused, formProps.errors.firstName)}/>
                                    }
                                    {...formProps}
                                />
                                <TextBox
                                    name="lastName"
                                    className="mb-15px"
                                    onChange={lastName => setState({...state, lastName})}
                                    onBlur={lastName => props.setField(UserFields.LAST_NAME, lastName)}
                                    placeHolder="Last Name"
                                    value={state.lastName}
                                    validationMessage={formProps.errors.lastName}
                                    prepend={(focused) =>
                                        <PersonIcon color={getIconColor(focused, formProps.errors.lastName)}/>
                                    }
                                    {...formProps}
                                />
                                <Email
                                    name="email"
                                    className="mb-15px"
                                    onChange={email => setState({...state, email})}
                                    onBlur={email => props.setField(UserFields.EMAIL, email)}
                                    placeHolder="Email"
                                    value={state.email}
                                    validationMessage={formProps.errors.email}
                                    prepend={(focused) =>
                                        <MailIcon color={getIconColor(focused, formProps.errors.email)}/>
                                    }
                                    {...formProps}
                                />
                                <div className="d-flex flex-row">
                                    <Telephone
                                        name="phone"
                                        className="sign-up-form__phone mb-15px mr-2"
                                        onChange={phone => {
                                            phone = phone.replace(/\D/g, '').substr(0, 10);
                                            setState({...state, phone})
                                        }}
                                        onBlur={phone => {
                                            phone = phone.replace(/\D/g, '').substr(0, 10);
                                            props.setField(UserFields.PHONE, phone)
                                        }}
                                        placeHolder="Phone"
                                        value={formatPhone(state.phone)}
                                        validationMessage={formProps.errors.phone}
                                        prepend={(focused) =>
                                            <PhoneIcon color={getIconColor(focused, formProps.errors.phone)}/>
                                        }
                                        {...formProps}
                                    />
                                    <TextBox
                                        name="zip"
                                        className="sign-up-form__zip mb-15px ml-2"
                                        onChange={zip => {
                                            if (!isNaN(Number(zip)) && zip.length <= 5) {
                                                setState({...state, zip})
                                            }
                                        }}
                                        onBlur={zip => props.setField(UserFields.ZIP, zip)}
                                        placeHolder="ZIP"
                                        value={state.zip}
                                        validationMessage={formProps.errors.zip}
                                        prepend={(focused) =>
                                            <ZipCodeIcon color={getIconColor(focused, formProps.errors.zip)}/>
                                        }
                                        {...formProps}
                                    />
                                </div>
                                <SelectList
                                    name="serviceLine"
                                    className="sign-up-form__service-line mb-15px"
                                    onChange={serviceLine => {
                                        setState({...state, serviceLine})
                                    }}
                                    onBlur={serviceLine => {
                                        props.setField(UserFields.WORK_TYPE_MAINTENANCE, false);
                                        props.setField(UserFields.WORK_TYPE_INSPECTIONS, false);
                                        props.setField(UserFields.WORK_TYPE_GRASS_AND_SNOW, false);
                                        props.setField(serviceLine, true);
                                    }}
                                    validationMessage={formProps.errors.serviceLine}
                                    list={serviceLines}
                                    placeHolder="Service Line*"
                                    prepend={(focused) =>
                                        <KabobIcon color={getIconColor(focused, formProps.errors.serviceLine)}/>
                                    }
                                    value={state.serviceLine}
                                    {...formProps}
                                />
                                {props.errorMessage ?
                                    <div>
                                        <p className="general-error">Could not create account.&nbsp;&nbsp;
                                            <Link to={Routes.requestForgotPassword}>Forgot Password</Link>
                                        </p>
                                    </div>
                                    : <Fragment/>
                                }

                                <ContinueButton/>

                                <div className="d-flex mx-auto mt-3">
                                    <span className="sign-up-form__already-signed-up">Already signed up?</span>
                                    <Link to={Routes.login} className="ml-3 d-flex">
                                        Log in
                                        <CaretIcon
                                            color={lightBlue}
                                            className="ml-2"
                                            style={{marginTop: "-2px"}}
                                        />
                                    </Link>
                                </div>
                            </Fragment>
                    }
                </Form>
            </WithLoader>
        </div>
    );
};

const formatPhone = (input: string): string => {
    let formatted = '';
    if (input.length > 0) {
        formatted = `(${input.substr(0, 3)}`;
    }
    if (input.length > 3) {
        formatted += `) ${input.substr(3, 3)}`;
    }
    if (input.length > 6) {
        formatted += `-${input.substr(6)}`;
    }

    let cleaned = '';
    for (let i = 0; i < formatted.length; i++) {
        const c = formatted[i];
        const check = (value: string, index: number): boolean => {
            return value === c && i === index;
        };

        if (!isNaN(Number(c)) || check('(', 0) || check(')', 4) || check(' ', 5) || check('-', 9)) {
            cleaned += c;
        }
    }
    return cleaned.substr(0, 14);
};

const mapStateToProps = (state: ApplicationState): StateProps => {
    if (!state.user) {
        return {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            zip: '',
            externalLeadRequest: '',
            errorMessage: '',
            serviceLine: '',
        };
    }
    let errorMessage: string = '';
    if (state.api && state.api && state.api.response && state.api.response.errors) {
        errorMessage = getApiError(state, "COULD_NOT_CREATE_ACCOUNT", "Could not create account.");
    }
    return {
        firstName: state.user.firstName || '',
        lastName: state.user.lastName || '',
        email: state.user.email || '',
        phone: state.user.phone || '',
        zip: state.user.zip || '',
        serviceLine: state.user.workTypeGrassAndSnow 
            ? UserFields.WORK_TYPE_GRASS_AND_SNOW 
            : state.user.workTypeInspections 
                ? UserFields.WORK_TYPE_INSPECTIONS 
                : state.user.workTypeMaintenance 
                    ? UserFields.WORK_TYPE_MAINTENANCE : '',
        externalLeadRequest: state.user.externalLeadRequest || '',
        errorMessage,
        isLoading: state.api.isFetching && !state.api.isSingleField
    };
};

export default connect(
    mapStateToProps,
    {
        setField: ActionCreators.setField,
        createAccount: API.createAccount
    }
)(SignUpForm);

const serviceLines = [
    {name: 'Service Line', value: ''},
    {name: 'Inspections', value: UserFields.WORK_TYPE_INSPECTIONS},
    {name: 'Grass & Snow', value: UserFields.WORK_TYPE_GRASS_AND_SNOW},
    {name: 'Maintenance', value: UserFields.WORK_TYPE_MAINTENANCE},
]
