import React from 'react';
import { Container, Row, Col } from "reactstrap";
import { Banner } from "../lib/Banner";
import BasicContent from '../lib/BasicContent';

export default () => {
    return (
        <BasicContent className="privacy-policy__wrapper">
            <Banner title={'Privacy Policy'} />
            <Container className="privacy-policy">
                <Row className="privacy-policy__body">
                    <Col xs="12"
                        md={{ offset: "1", size: "10" }}
                        lg={{ offset: "2", size: "8" }}>
                        <div>
                            <p>
                                Safeguard Properties only has access to or collects information that you voluntarily give us via email or other direct contact from you. This Web site employs the use of cookies to enhance your browsing experience, and may use them to collect personally identifiable data such as your email address and page views.
                            </p>
                            <p>
                                We may use the information we gather about you to contact you via email, post, or telephone; to send you newsletters ("All Client Alerts"); and to view your activity on the Web site.
                            </p>
                            <p>
                                We will not share your information with any third party outside of our organization.
                            </p>
                            <p>
                                You may opt out of receiving any future contact from us at any time.
                            </p>
                            <p>
                                This includes an "opt out notice" in any future email we may send you. You can see what data we have about you, if any, and update/correct/delete that data at anytime by contacting us via the email or phone number given on our Web site.
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </BasicContent>
    );
};

