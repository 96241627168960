import {Action, Reducer} from "redux";
import {ApiAction} from "./api";
import {documentType} from "../api";

export interface DocumentUploadState {
    photoIdUploading?: boolean;
    photoIdUploadFailed?: boolean;
    backgroundCheckUploading?: boolean;
    backgroundCheckUploadFailed?: boolean;
    insuranceUploading?: boolean;
    insuranceUploadFailed?: boolean;
}

export const documentUploadReducer: Reducer<DocumentUploadState> = (state: DocumentUploadState | undefined, incomingAction: Action): any => {
    const action = incomingAction as ApiAction<{type: string}>;
    if (state === undefined) {
        return {
            photoIdUploading: false,
            photoIdUploadFailed: false,
            backgroundCheckFailed: false,
            backgroundCheckUploadSuccess: false,
            insuranceUploading: false,
            insuranceUploadFailed: false
        };
    }
    if (action.type === "POST_DOCUMENT" && action.payload.requestData) {
        const docType = action.payload.requestData.type;
        
        if (docType === documentType.photoId) {
            return {...state, photoIdUploading: true}
        }
        if (docType === documentType.backgroundCheck) {
            return {...state, backgroundCheckUploading: true}
        }
        if (docType === documentType.insurance) {
            return {...state, insuranceUploading: true}
        }
    }
    
    if (action.type === "POST_DOCUMENT_SUCCESS" && action.payload.data.data) {
        const data = action.payload.data.data;
        const docType = data.type;
        const failed = !data.success as boolean;

        if (docType === documentType.photoId) {
            return {...state, photoIdUploading: false, photoIdUploadFailed: failed}
        }
        if (docType === documentType.backgroundCheck) {
            return {...state, backgroundCheckUploading: false, backgroundCheckUploadFailed: failed}
        }
        if (docType === documentType.insurance) {
            return {...state, insuranceUploading: false, insuranceUploadFailed: failed}
        }
    }
    return state;
};
