import React from "react";
import { Col, Container, Row } from "reactstrap";
import locationValleyView from "../../../assets/location_valleyview.png";
import locationRichardson from "../../../assets/location_richardson.png";
import "./styles.scss";
import ExternalLink from "../../lib/ExternalLink";

export default () => {
    return (
        <div className="contact-us__locations d-flex w-100">
            <Container className="d-flex w-100">
                <Row className="align-self-center">
                    <Col xs="12" className="text-center">
                        <h2 className="contact-us__locations--title">
                            Our Locations
                    </h2>
                    </Col>
                    <Col
                        xs="12"
                        lg="6"
                        className="text-center align-self-start"
                    >
                        <ExternalLink
                            className="contact-us__locations--link"
                            to="https://www.google.com/maps/place/Safeguard+Properties+Management+LLC/@41.3607502,-81.5926701,15z/data=!4m5!3m4!1s0x0:0xc98978fee0cc8559!8m2!3d41.3607502!4d-81.5926701"
                        >
                            <img
                                alt="Valley View, OH"
                                src={locationValleyView}
                                className="contact-us__locations--image img-fluid"
                            />
                        </ExternalLink>
                        <div className="contact-us__locations--text">
                            <h3>Valley View</h3>
                            <p>7887 Safeguard Circle (HUB PKWY)<br />Valley View, OH 44125</p>
                        </div>
                    </Col>
                    <Col
                        xs="12"
                        lg="6"
                        className="text-center align-self-start"
                    >
                        <ExternalLink
                            className="contact-us__locations--link"
                            to="https://www.google.com/maps/place/2400+Lakeside+Blvd+%23300,+Richardson,+TX+75082/@32.9820376,-96.7130847,17z/data=!3m1!4b1!4m5!3m4!1s0x864c1f2945ced853:0x6ac4a3ad500f3f71!8m2!3d32.9820376!4d-96.710896"
                        >
                            <img
                                alt="Richardson, TX"
                                src={locationRichardson}
                                className="contact-us__locations--image img-fluid"
                            />
                        </ExternalLink>
                        <div className="contact-us__locations--text">
                            <h3>Richardson</h3>
                            <p>2400 Lakeside Blvd. #300<br />Richardson, TX 75082</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};
