import * as React from 'react';
import './styles.scss';
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from 'react-router';
import { connect } from "react-redux";
import { ApplicationState } from "./store";
import { isLoggedIn, isTempLoggedIn, isForgotPasswordTempLoggedIn } from "./sagas/login";
import Layout from './components/Layout';
import Home from './components/HomePage';
import AboutUs from './components/AboutUsPage';
import CreatePassword from './components/CreatePasswordPage';
import DashboardPage from './components/DashboardPage';
import BackgroundCheckPage from './components/BackgroundCheckPage';
import CompanyInfoPage from './components/CompanyInfoPage';
import IdentificationPage from './components/IdentificationPage';
import InsurancePage from './components/InsurancePage';
import TermsPage from './components/TermsPage';
import TermsOfUsePage from './components/TermsOfUsePage';
import FAQPage from './components/FAQPage';
import PrivacyPolicyPage from './components/PrivacyPolicyPage';
import ContactUsPage from './components/ContactUsPage';
import RequestForgotPasswordPage from './components/RequestForgotPasswordPage';
import ForgotPasswordPage from './components/ForgotPasswordPage';
import CompletionPage from './components/CompletionPage';
import { Routes } from "./routes";
import LoginPage from "./components/LoginPage";
import { useEffect } from "react";
import { API } from "./api";
import { hasContent } from './lib/helpers';

interface StateProps {
    isLoggedIn: boolean;
    isTempAccount: boolean;
    isForgotPasswordTempAccount: boolean;
}

interface DispatchProps {
    getUser: () => void;
}

type Props = StateProps & DispatchProps;

const RoutesComponent = (props: Props) => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://assets.cloudfil.es/cloudfiles.js";
        script.async = false;
        document.body.appendChild(script);

        const script2 = document.createElement('script');
        script2.src = "/cloudfilesinit.js";
        script2.async = false;
        document.body.appendChild(script2);

        return () => {
            document.body.removeChild(script);
            document.body.removeChild(script2);
        }
    }, []);
    useEffect(() => {
        try {
            if (props.isLoggedIn || props.isTempAccount) {
                props.getUser();
            }
            window.scrollTo(0, 0);
        } catch (e) {
        }
    }, [props]);
    return (
        <Layout>
            <Switch>
                <Route
                    exact
                    path={Routes.createPassword}
                    render={() => props.isTempAccount
                        ? <CreatePassword />
                        : props.isLoggedIn
                            ? <Redirect to={Routes.dashboard} />
                            : <Redirect to={Routes.home} />
                    }
                />

                <Route
                    exact
                    path={Routes.home}
                    render={(p) => (
                        props.isTempAccount
                            ? <Redirect to={Routes.createPassword} />
                            : props.isLoggedIn
                                ? <Redirect to={Routes.dashboard} />
                                    : <Home referral={p.location.search}/>
                        )
                    }
                />
                <Route exact path={Routes.dashboard} render={getRender({ component: DashboardPage, ...props })} />
                <Route
                    exact
                    path={Routes.backgroundCheck}
                    render={getRender({ component: BackgroundCheckPage, ...props })}
                />
                <Route exact path={Routes.companyInfo} render={getRender({ component: CompanyInfoPage, ...props })} />
                <Route
                    exact
                    path={Routes.identification}
                    render={getRender({ component: IdentificationPage, ...props })}
                />
                <Route exact path={Routes.insurance} render={getRender({ component: InsurancePage, ...props })} />
                <Route exact path={Routes.complete} render={getRender({ component: CompletionPage, ...props })} />

                <Route exact path={Routes.terms} component={TermsPage} />
                <Route exact path={Routes.aboutUs} component={AboutUs} />
                <Route exact path={Routes.faq} component={FAQPage} />
                <Route exact path={Routes.privacyPolicy} component={PrivacyPolicyPage} />
                <Route exact path={Routes.contactUs} component={ContactUsPage} />
                <Route exact path={Routes.termsOfUse} component={TermsOfUsePage} />
                <Route
                    exact
                    path={Routes.requestForgotPassword}
                    render={() => props.isLoggedIn
                        ? <Redirect to={Routes.dashboard} />
                        : <RequestForgotPasswordPage />}
                />
                <Route
                    exact
                    path={Routes.forgotPassword + '/:token'}
                    render={(p) => hasContent(p.match.params.token) || props.isForgotPasswordTempAccount
                        ? <ForgotPasswordPage {...p} />
                        : <Redirect to={Routes.home} />} />

                <Route
                    exact
                    path={Routes.login}
                    render={() => props.isTempAccount
                        ? <Redirect to={Routes.createPassword} />
                        : props.isLoggedIn
                            ? <Redirect to={Routes.dashboard} />
                            : <LoginPage />
                    }
                />
                <Route
                    exact
                    render={() => props.isLoggedIn
                        ? <Redirect to={Routes.dashboard} />
                        : <Redirect to={Routes.home} />
                    }
                />
            </Switch>
        </Layout>
    );
};

interface getRenderProps {
    isTempAccount: boolean;
    isLoggedIn: boolean;
    component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
}

const getRender = (props: getRenderProps) => () => {
    return (
        props.isTempAccount
            ? <Redirect to={Routes.createPassword} />
            : props.isLoggedIn
                ? React.createElement(props.component, {})
                : <Redirect to={Routes.home} />
    );
};

export default withRouter(connect(
    (state: ApplicationState) => {
        if (!state.user) {
            return {
                isLoggedIn: false,
                isTempAccount: false,
                isForgotPasswordTempAccount: false
            };
        }
        return {
            isLoggedIn: isLoggedIn(),
            isTempAccount: isTempLoggedIn(),
            isForgotPasswordTempAccount: isForgotPasswordTempLoggedIn()
        };
    },
    {
        getUser: API.getUser,
    }
)(RoutesComponent) as any);
