import React, {FormEvent, Fragment, FunctionComponent, ReactNode, useEffect, useState} from "react";
import {some} from 'lodash';
import {Button, Input, InputGroup, InputGroupAddon, InputGroupText, Label, Row, Col} from "reactstrap";
import {Form as StrapForm} from "reactstrap";
import {dashToCamelCase, hasContent} from "../../lib/helpers";
import classNames from "classnames";
import {CaretIcon} from "../../assets/icons";

type ChangeHandler = (value: string, event?: FormEvent<HTMLInputElement>) => void;
const callChangeHandler = (
    func?: ChangeHandler,
    e?: FormEvent<HTMLInputElement>,
    getTargetValue: (e: FormEvent<HTMLInputElement>) => any = event => event.currentTarget.value) =>
    func && func(getTargetValue(e!), e);

export type FormProps<T> = {
    onSubmit: (form: any, event?: FormEvent) => void;
    values: T,
    onChange?: (form: any) => void;
    className?: string;
    validate?: (values: any) => object;
    noReload?: boolean;
    children: (p: FormChildProps) => ReactNode;
}

export type FormChildProps = {
    handleChange: ChangeHandler;
    errors: any;
}

export function Form<T>(props: FormProps<T>) {
    const [formErrors, setFormErrors] = useState({});
    const [form, setForm] = useState(props.values);

    useEffect(() => setForm(props.values), [props.values]);

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        const errors = validate(form);
        setFormErrors(errors);
        const isError = some(errors);
        if (props.noReload) {
            event.preventDefault();
        }
        setForm(form);
        !isError && props.onSubmit(form, event);
        setForm(form);
    };

    const handleChange = (value?: string, event?: FormEvent<HTMLInputElement>) => {
        const target = event!.currentTarget;
        const name = dashToCamelCase(target.name || target.id);
        const newState = {...form, [name]: target.value} as T;
        setForm(newState);
    };

    const validate = (values: any): any => props.validate && props.validate(values);

    return (
        <Fragment>
            <StrapForm className={props.className} onSubmit={handleSubmit}>
                {props.children({
                    handleChange,
                    errors: formErrors
                })}
            </StrapForm>
        </Fragment>
    );
}

type FieldProps = {
    id?: string;
    name: string;
    value?: string;
    checked?: boolean;
    className?: string;
    placeHolder?: string;
    label?: string;
    onChange?: ChangeHandler;
    handleChange?: ChangeHandler;
    onBlur?: ChangeHandler;
    onFocus?: () => void;
    validationMessage?: string;
    errors?: any;
    getTargetValue?: (event: FormEvent<HTMLInputElement>) => any;
    pattern?: string;
    type?: any;
    list?: { name: string, value: string }[];
    autoFocus?: boolean;
    style?: object;
    prepend?: (focused: boolean) => ReactNode;
    noDefaultStyles?: boolean;
    validate?: (value?: string) => string | undefined | null;
}

export const Field: FunctionComponent<FieldProps> = props => {
    const [focus, setFocus] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.keyCode === 13) {
            callChangeHandler(props.onBlur, e, props.getTargetValue)
        }
    };

    const focusStyle = focus ? "input-text-focus input-focus" : "input-text";
    const classes = classNames(
        !!props.noDefaultStyles ? "" : focusStyle,
        props.errors && props.errors[props.name] ? "error" : ""
    );
    return (
        <div className={props.className}>
            {renderLabelIfExists(props.name, props.label || '')}
            <InputGroup
                className={classes}
                style={props.style}
            >
                {
                    props.prepend &&
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText
                            className={classNames("input-prepend", focus && "input-prepend-focus")}>{props.prepend(focus)}</InputGroupText>
                    </InputGroupAddon>
                }
                <Input
                    id={props.id}
                    name={props.name}
                    value={props.value}
                    checked={props.checked}
                    placeholder={props.placeHolder}
                    onChange={e =>
                        callChangeHandler(props.onChange, e, props.getTargetValue)
                        && callChangeHandler(props.handleChange, e, props.getTargetValue)
                    }
                    onBlur={e => {
                        if (props.validate) {
                            var errorMessage = props.validate(e.currentTarget.value);
                            if (hasContent(errorMessage)) {
                                setErrorMessage(errorMessage || '');
                            }
                        }
                        setFocus(false);
                        callChangeHandler(props.onBlur, e, props.getTargetValue);
                    }}
                    onFocusCapture={() => setFocus(true)}
                    type={props.type}
                    pattern={props.pattern}
                    onKeyDown={(e) => handleKeyPress(e)}
                    autoFocus={props.autoFocus}
                    onFocus={props.onFocus}
                />
            </InputGroup>
            {
                renderErrorIfExists(
                    hasContent(errorMessage) || (props.errors && props.errors[props.name]),
                    hasContent(errorMessage) ? errorMessage : (props.validationMessage || '')
                )
            }
        </div>
    );
};

const renderErrorIfExists = (error: any, message: string) => {
    return error && <Label className="form-error my-0">{message}</Label>;
};
const renderLabelIfExists = (name: string, message: string) => {
    return (message ? <Label htmlFor={name} className="form-label">{message}</Label> : <Fragment/>);
};

export const TextBox: FunctionComponent<FieldProps> = props => <Field {...props} />;
export const MessageBox: FunctionComponent<FieldProps> = props => <Field type="textarea" noDefaultStyles {...props} />;
export const Telephone: FunctionComponent<FieldProps> = props => <Field type="tel" {...props} />;
export const Email: FunctionComponent<FieldProps> = props => <Field type="email" {...props} />;
export const Password: FunctionComponent<FieldProps> = props => <Field type="password" {...props} />;

export const SelectList: FunctionComponent<FieldProps> = props => {
    const options = props.list || [];
    const [focus, setFocus] = useState(false);

    const focusStyle = focus ? "input-text-focus input-focus" : "input-text";
    const classes = classNames(
        focusStyle,
        props.errors && props.errors[props.name] ? "error" : "",
        'select-input-group'
    );
    return (
        <div className={classNames("select-div", props.className)}>
            {renderLabelIfExists(props.name, props.label || '')}
            <InputGroup
                className={classes}
                style={props.style}
            >
                {
                    props.prepend &&
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText
                            className={classNames("input-prepend", focus && "input-prepend-focus")}
                        >{props.prepend(focus)}
                        </InputGroupText>
                    </InputGroupAddon>
                }
                <Input
                    type="select"
                    id={props.id}
                    name={props.name}
                    value={props.value}
                    className={classNames(props.className, "pointer", "mx-0")}
                    placeholder={props.placeHolder}
                    onFocusCapture={() => setFocus(true)}
                    onChange={e => {
                        setFocus(false);
                        callChangeHandler(props.onChange, e, e => e.currentTarget.value)
                        && callChangeHandler(props.handleChange, e, e => e.currentTarget.value)
                    }}
                    onBlur={e => {
                        setFocus(false);
                        callChangeHandler(props.onBlur, e, props.getTargetValue);
                    }}
                >
                    {options.map(x => <option key={x.value} value={x.value}>{x.name}</option>)}
                </Input>
            </InputGroup>
            {renderErrorIfExists(props.errors && props.errors[props.name], props.validationMessage || '')}
        </div>
    );
};

interface ButtonProps {
    className?: string;
    color?: string;
    type?: string;
    onClick?: () => void;
    children?: ReactNode;
    center?: boolean;
    to?: string;
}


export const RawButton = (props: ButtonProps) => (
    <Button
        type={props.type || 'submit'}
        className={classNames("continue-btn", {'continue-btn--center': props.center}, props.className)}
        onClick={() => props.onClick && props.onClick()}
    >
        {props.children}
    </Button>
);

export const ContinueButton = (props: ButtonProps) => (
    props.to != undefined ?
        <a
            href={props.to}
            target="_blank"
            rel="noopener noreferrer"
            className={classNames("continue-btn", {'continue-btn--center': props.center}, props.className)}
        >
            <Fragment>
                {props.children ? props.children : <span>Continue</span>}
                <CaretIcon color="white"/>
            </Fragment>
        </a> :
        <RawButton {...props}>
            {
                <Fragment>
                    {props.children ? props.children : <span>Continue</span>}
                    <CaretIcon color="white"/>
                </Fragment>
            }
        </RawButton>
);

export const getIconColor = (focused: boolean, error: string) =>
    focused ? "#005288" : hasContent(error) ? "#ff0000" : "#9a9a9a";

export const ExpandedContinueButton = (props: ButtonProps) => (
    <Row className="expanded-continue-btn">
        <Col xs="12 p-xs-0 p-sm-0 p-md-0">
            <ContinueButton {...props}>
                {props.children || <span>Continue to Next Step</span>}
            </ContinueButton>
        </Col>
    </Row>
);