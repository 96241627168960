import { Actions, areAllStepsComplete, UserFields, ActionCreators } from "../store/User";
import { ContinueFromRoute, GenericAction } from "../store";
import { takeEvery, put, select } from "redux-saga/effects";
import { Routes } from "../routes";
import { push } from "connected-react-router";
import { TOKEN_EXPIRED } from "../store/messages";
import { clearTempJwtFromStorage } from "./login";

function* handleContinueFromPage(action: ContinueFromRoute) {
    var user = yield select(s => s.user);
    var isComplete = false;
    if (!!user) {
        var viewedCompletionPage = !!user.viewedCompletionPage;
        isComplete = areAllStepsComplete(user) || false;
        if (isComplete && (!viewedCompletionPage || action.payload.currentRoute === Routes.terms)) {
            yield put(push(Routes.complete));
            yield put(ActionCreators.setField(UserFields.VIEWED_COMPLETION_PAGE, true));
            return;
        }
    }
    let navigateTo = '';
    switch (action.payload.currentRoute) {
        case Routes.companyInfo:
            navigateTo = Routes.identification;
            break;
        case Routes.identification:
            navigateTo = Routes.backgroundCheck;
            break;
        case Routes.backgroundCheck:
            navigateTo = Routes.insurance;
            break;
        case Routes.insurance:
            navigateTo = Routes.terms;
            break;
        case Routes.terms:
            navigateTo = Routes.dashboard;
            break;
        default:
            navigateTo = Routes.dashboard;
    }
    yield put(push(navigateTo));
}

function* goToDashboard(action: GenericAction) {
    var user = yield select(s => s.user);
    var isComplete = false;
    if (!!user) {
        var viewedCompletionPage = !!user.viewedCompletionPage;
        isComplete = areAllStepsComplete(user) || false;
        if (isComplete && !viewedCompletionPage) {
            yield put(push(Routes.complete));
            yield put(ActionCreators.setField(UserFields.VIEWED_COMPLETION_PAGE, true));
            return;
        }
    }
    yield put(push(Routes.dashboard));
}

function* handleTokenExpired(action: GenericAction) {
    clearTempJwtFromStorage();
    yield put(push(Routes.login));
}

export function* navigationSaga() {
    yield takeEvery(Actions.CONTINUE_FROM_PAGE, handleContinueFromPage);
    yield takeEvery(Actions.GO_TO_DASHBOARD, goToDashboard);
    yield takeEvery(TOKEN_EXPIRED, handleTokenExpired);
}