import { Actions as UserActions } from "../store/User";
import { ApiAction, ApiStatusAction, API_STATUS_CODE } from "../store/api";
import { push } from "connected-react-router";
import { put, takeEvery } from "redux-saga/effects";
import { Routes } from "../routes";
import { hasContent } from "../lib/helpers";

const jwtTempTokenName = 'safeguard-vendor-temp-token';
const jwtForgotPasswordTempTokenName = 'safeguard-vendor-forgot-password-temp-token';
const jwtTokenName = 'safeguard-vendor-token';

const setJwtToLocalStorage = (tokenName: string, token: string) => {
    try {
        window.localStorage.setItem(tokenName, token);
    } catch {
    }
};

export function setTempToken(token: string) {
    setJwtToLocalStorage(jwtTempTokenName, token);
}

export function setForgotPasswordTempToken(token: string) {
    setJwtToLocalStorage(jwtForgotPasswordTempTokenName, token);
}

const getJwtFromLocalStorage = (tokenName: string) => {
    try {
        return window.localStorage.getItem(tokenName);
    } catch {
        return null;
    }
};

const clearJwtFromLocalStorage = (tokenName: string) => {
    try {
        window.localStorage.removeItem(tokenName);
    } catch (e) {
    }
};

export function clearTempJwtFromStorage() {
    clearJwtFromLocalStorage(jwtTempTokenName);
}

export function getJwt() {
    const forgotPasswordTempToken = getJwtFromLocalStorage(jwtForgotPasswordTempTokenName);
    const tempToken = getJwtFromLocalStorage(jwtTempTokenName);
    const token = getJwtFromLocalStorage(jwtTokenName);

    if (hasContent(token)) {
        return token;
    }
    if (hasContent(tempToken)) {
        return tempToken;
    }
    if (hasContent(forgotPasswordTempToken)) {
        return forgotPasswordTempToken;
    }
    return null;
};

export function isLoggedIn() {
    return !!getJwtFromLocalStorage(jwtTokenName);
}

export function isTempLoggedIn() {
    return !!getJwtFromLocalStorage(jwtTempTokenName);
}

export function isForgotPasswordTempLoggedIn() {
    return !!getJwtFromLocalStorage(jwtForgotPasswordTempTokenName);
}

const getToken = (action: ApiAction) => action.payload.data.data.token;

function* handleCreateAccount(action: ApiAction) {
    const token = getToken(action);
    setTempToken(token);
    yield put(push(Routes.createPassword));
}

function* handleCreateAccountSetPassword(action: ApiAction) {
    const token = getToken(action);
    setJwtToLocalStorage(jwtTokenName, token);
    clearJwtFromLocalStorage(jwtTempTokenName);
    clearJwtFromLocalStorage(jwtForgotPasswordTempTokenName);
    yield put(push(Routes.dashboard));
}

function* handleLogout() {
    clearJwtFromLocalStorage(jwtTokenName);
    clearJwtFromLocalStorage(jwtForgotPasswordTempTokenName);
    clearJwtFromLocalStorage(jwtTempTokenName);
    try {
        window.location.reload();
    } catch (e) {
        yield put(push(Routes.home));
    }
}

function* handleLoginSuccess(action: ApiAction) {
    const token = getToken(action);
    setJwtToLocalStorage(jwtTokenName, token);
    yield put(push(Routes.dashboard));
}

function* handleStatusCode(action: ApiStatusAction) {
    if (action.payload.status === 401) {
        yield handleLogout();
    }
}

export function* loginSaga() {
    yield takeEvery(UserActions.POST_ACCOUNT_SUCCESS, handleCreateAccount);
    yield takeEvery(UserActions.POST_ACCOUNT_PASSWORD_SUCCESS, handleCreateAccountSetPassword);
    yield takeEvery(UserActions.LOGOUT, handleLogout);
    yield takeEvery(UserActions.POST_LOGIN_SUCCESS, handleLoginSuccess);
    yield takeEvery(API_STATUS_CODE, handleStatusCode);
}