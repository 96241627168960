import React, { useEffect, useState, Fragment } from 'react';
import "./styles.scss";
import { connect } from 'react-redux';
import { ContinueButton, Email, Form, FormChildProps, getIconColor, Password } from "../../Form";
import { Label } from "reactstrap";
import { API } from "../../../api";
import { ApplicationState } from "../../../store";
import { getRequiredMsg, hasContent } from "../../../lib/helpers";
import { getApiError } from "../../../store/helpers";
import { Link } from "react-router-dom";
import { Routes } from "../../../routes";
import { MailIcon, PasswordIcon } from "../../../assets/icons";

interface StateProps {
    isLoading?: boolean;
    apiError?: string;
}

interface DispatchProps {
    onContinue?: () => void;
    onFailure?: () => void;
    logIn: (data: { email: string, password: string }) => void;
}

type Props = StateProps & DispatchProps;

class LoginErrors {
    email?: string;
    password?: string;
}

const LoginForm = (props: Props) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { apiError } = props;

    const handleSubmit = (f: Props) => {
        props.logIn({ email, password });
    };

    useEffect(() => {
        if (hasContent(apiError)) {
            props.onFailure && props.onFailure();
        }

    });

    return (
        <div className="login__form">
            <Form
                noReload
                onSubmit={handleSubmit}
                validate={(f: { email: string, password: string }) => {
                    const errors = new LoginErrors();
                    errors.email = getRequiredMsg(f.email, 'Email');
                    errors.password = getRequiredMsg(f.password, 'Password');
                    return errors;
                }}
                values={{
                    email,
                    password
                }}
            >
                {
                    (formProps: FormChildProps) =>
                        <Fragment>
                            <Label className="general-error">{props.apiError}</Label>
                            <Email
                                name="email"
                                placeHolder="Email"
                                className="mb-15px"
                                onChange={v => setEmail(v)}
                                value={email}
                                validationMessage={formProps.errors.email}
                                prepend={(focused) =>
                                    <MailIcon
                                        color={getIconColor(focused, formProps.errors.email)}
                                    />
                                }
                                {...formProps}
                            />
                            <Password
                                name="password"
                                placeHolder="Password"
                                className="mb-15px"
                                onChange={v => setPassword(v)}
                                value={password}
                                validationMessage={formProps.errors.password}
                                prepend={(focused) =>
                                    <PasswordIcon color={getIconColor(focused, formProps.errors.password)} />
                                }
                                {...formProps}
                            />
                            <div className="mt-30px w-100">
                                <Link to={Routes.requestForgotPassword} className="login-form__forgot-password">Forgot Password</Link>
                                <ContinueButton onClick={props.onContinue} className="mt-15px"/>
                            </div>
                        </Fragment>
                }
            </Form>
        </div>
    );
};

export default connect(
    (state: ApplicationState) => {
        if (!state || !state.api) {
            return { apiError: '', isLoading: false };
        }

        return {
            isLoading: state.api.isFetching,
            apiError: getApiError(state, "INVALID_CREDENTIALS", "Invalid Email or Password")
        };
    },
    {
        logIn: API.logIn
    }
)(LoginForm);
