import React from "react";
import {LogoSmall} from "../../assets/icons";
import {Link} from "react-router-dom";
import {Routes} from "../../routes";
import classNames from "classnames";

const legalList = [{
    route: Routes.privacyPolicy,
    name: "Privacy Policy"
}, {
    route: Routes.termsOfUse,
    name: "Terms of Use"
}];

const navList = [{
    route: Routes.aboutUs,
    name: "About"
}, {
    route: Routes.faq,
    name: "FAQ"
}, {
    route: Routes.contactUs,
    name: "Contact Us"
}];

export default () => (
    <footer className="footer">
        <div className="container-fluid footer__container">
            <Link to={Routes.home} className="footer__logo d-none d-md-block">
                <LogoSmall className="align-self-center"/>
            </Link>
            <LinkList
                className="footer__list"
                listItemsClass="d-flex justify-content-md-center justify-content-start w-100"
                list={legalList}
            />
            <LinkList
                list={navList}
                className="footer__list border-xs-bottom"
                listItemsClass="ml-auto mx-xs-auto justify-content-end flex-xs-column flex-row w-100"
                listDirection=""
            />
        </div>
    </footer>
);

interface LinkListItem {
    route: string;
    name: string;
}

interface LinkListProps {
    style?: any;
    className: string;
    listDirection?: string;
    listItemsClass?: string;
    itemClass?: string;
    list: LinkListItem[];
}

const LinkList = (props: LinkListProps) => {
    const listDirection = props.listDirection || "flex-row";
    return (
        <div style={props.style} className={classNames(props.className, listDirection)}>
            <div className={classNames("d-flex", props.listItemsClass)}>
                {props.list.map((x, i) =>
                    <Link
                        key={x.route}
                        to={x.route}
                        className={classNames("px-2", "mx-xs-auto", i !== props.list.length ? "pb-xs-15" : "")}
                    >
                        {x.name}
                    </Link>
                )}
            </div>
        </div>
    );
};