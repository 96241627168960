import React from 'react';
import { Col, Container, Row } from "reactstrap";
import Accordion from "./Accordion";
import { Banner } from "../lib/Banner";
import { CtaBanner } from "../lib/CtaBanner";
import BasicContent from '../lib/BasicContent';

export default () => {
    return (
        <BasicContent className="faq__wrapper">
            <Banner title={'Frequently Asked Questions'} />
            <Container className="faq">
                <Row className="faq__body">
                    <Col xs="12"
                        md={{ offset: "1", size: "10" }}
                        lg={{ offset: "2", size: "8" }}>
                        <div className="faqAccordions">
                            <Accordion />
                        </div>
                    </Col>
                </Row>
            </Container>
            <CtaBanner
                title={'Do Work That Matters'}
                text={'Join Safeguard\'s elite vendor network and be your own boss, grow your business, make more money, all while helping your community.'}
            />
        </BasicContent>
    );
};
