import {call, put, takeEvery, select} from 'redux-saga/effects';
import {Actions as UserActions} from "../store/User";
import Axios, {AxiosResponse} from "axios";
import {ApiAction, API_STATUS_CODE} from "../store/api";
import {getJwt} from "./login";
import {API} from "../api";

const getHeaders = () => ({'Authorization': 'Bearer ' + getJwt()});

const getCall = (action: ApiAction) => Axios.get(action.payload.url, {headers: getHeaders()});

const postCall = (action: ApiAction) => Axios.post(action.payload.url, action.payload.requestData, {
    headers: getHeaders()
});

const putCall = (action: ApiAction) => Axios.put(
    action.payload.url,
    action.payload.requestData, {
        headers: getHeaders()
    }
);

function* calling(action: ApiAction) {
    yield put({
        type: `${action.type}_CALLING`,
        payload: {
            isFetching: true,
            isSingleField: action.payload.isSingleField,
            getUserAfterCall: action.payload.getUserAfterCall,
        }
    });
}

function* putAction(
    state: 'SUCCESS' | 'FAILED' | 'DELETE' | 'PUT',
    action: ApiAction,
    data: object,
    status: number) {
    yield put({
        type: `${action.type}_${state}`,
        payload: {
            isFetching: false,
            isSingleField: action.payload.isSingleField,
            getUserAfterCall: action.payload.getUserAfterCall,
            status,
            data
        }
    });
}

function* performCall(
    apiCall: (apiAction: ApiAction) => Promise<AxiosResponse<any>>,
    action: ApiAction
) {
    yield calling(action);
    try {
        const {data, status} = yield call(apiCall, action);
        yield putAction('SUCCESS', action, data, status);
        yield put({
            type: API_STATUS_CODE,
            payload: {
                status
            }
        });
    } catch (e) {
        const status = e.response.status;
        const data = e.response.data;
        yield putAction('FAILED', action, data, status);
        yield put({
            type: API_STATUS_CODE,
            payload: {
                ...data,
                status
            }
        });
    }
}

function* handleDocumentUpload(apiAction: ApiAction) {
    yield performCall(postCall, apiAction);
    yield put(API.getUser());
}

function* handlePutField(apiAction: ApiAction) {
    yield performCall(putCall, apiAction);

    const shouldGetUser = yield select(state => state.api.getUserAfterCall);
    if (shouldGetUser) {
        yield put(API.getUser());
    }
}

export function* apiSaga() {
    yield takeEvery(UserActions.GET_USER, performCall, getCall);
    yield takeEvery(UserActions.PUT_FIELD, handlePutField);
    yield takeEvery([
            UserActions.POST_ACCOUNT,
            UserActions.POST_ACCOUNT_PASSWORD,
            UserActions.POST_FORGOT_PASSWORD,
            UserActions.POST_LOGIN],
        performCall,
        postCall
    );

    yield takeEvery(
        UserActions.POST_DOCUMENT,
        handleDocumentUpload
    );
}