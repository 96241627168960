import React, {ReactNode} from "react";
import {BackgroundImage} from "../../BackgroundImage";
import "./styles.scss";
import classNames from "classnames";
import {Col, Container, Row} from "reactstrap";

interface Props {
    className: string;
    children: ReactNode;
    style?: any;
}

export default (props: Props) => {
    return (
        <div className="my-5">
            <BackgroundImage className="h-100" absolute fullPageHeight/>
            <div className="simple-page-scaffold d-flex justify-content-center align-items-center h-100">
                <Container>
                    <Row>
                        <Col xs="12" sm={{offset: 1, size: 10}} md={{offset: 2, size: 8}} lg={{offset: 3, size: 6}}>
                            <div
                                className={classNames(props.className, "simple-page-scaffold__box w-sm-100 h-100 gray-border d-flex justify-content-center")}
                                style={props.style}>
                                {props.children}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
};

