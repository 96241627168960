import React, { Fragment, useState, useEffect } from 'react';
import { Navbar, NavbarBrand, NavItem, NavLink, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Routes } from "../../routes";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import { ActionCreators } from "../../store/User";
import { isLoggedIn, isTempLoggedIn } from "../../sagas/login";
import { RouterState } from "connected-react-router";
import { LogoBig, LogoMedium, CaretIcon, LogoSmall } from "../../assets/icons";
import { isInViewPort } from '../../lib/helpers';
import classNames from 'classnames';

interface NavMenuProps {
    logOut: () => void;
    goToDashboard: () => void;
    isLoggedIn: boolean;
    isTempLoggedIn: boolean;
    isLoginPage: boolean;
    isHomePage: boolean,
    isDashboard: boolean,
    isDashboardStep: boolean,
    isSimpleScaffoldPage: boolean
    isContentPage: boolean;
}

function NavMenu(props: NavMenuProps) {
    return (
        <header>
            {
                props.isHomePage
                    ? <HomePageNavMenu {...props} />
                    : props.isSimpleScaffoldPage
                        ? <SimpleScaffoldNavMenu {...props} />
                        : props.isDashboardStep
                            ? <DashboardStepNavMenu {...props} />
                            : <DefaultNavMenu {...props} />
            }
        </header>
    );
}

const SimpleScaffoldNavMenu = (props: NavMenuProps) => (
    <Navbar className="nav-menu nav-menu__simple pr-30px pl-30px">
        <div className="d-flex w-100 justify-content-between align-items-center">
            <NavbarBrand tag={Link} to={Routes.home}>
                <Logos colorSmall="#005288" colorBig="white"/>
            </NavbarBrand>
            {!props.isTempLoggedIn ? LogInLogOutButton(props) : <Fragment />}
        </div>
    </Navbar>
);

const DefaultNavMenu = (props: NavMenuProps) => (
    <Navbar className="nav-menu nav-menu__authenticated nav-menu--border pr-30px pl-30px" fixed={props.isContentPage ? "top" : ""}>
        <div className="d-flex w-100 justify-content-between align-items-center">
            <NavbarBrand tag={Link} to={Routes.home}>
                <Logos colorSmall="#005288" colorBig="#005288"/>
            </NavbarBrand>
            {!props.isTempLoggedIn ? LogInLogOutButton(props) : <Fragment />}
        </div>
    </Navbar>
);

const DashboardStepNavMenu = (props: NavMenuProps) => (
    <Navbar className="nav-menu nav-menu__authenticated nav-menu--border pr-30px pl-30px" fixed="top">
        <div className="d-flex w-100 justify-content-between align-items-center">
            <NavbarBrand tag={Link} to={Routes.home}>
                <Logos colorSmall="#005288" colorBig="#005288"/>
            </NavbarBrand>
            <BackToDashboard {...props}/>
        </div>
    </Navbar>
);

const BackToDashboard = (props: NavMenuProps) => (
    <a onClick={props.goToDashboard} className="nav-menu__back-link d-inline-flex flex-row">
        <span className="nav-menu__back-caret">
            <CaretIcon color="#005288" />
        </span>
        <span>
            Back to Dashboard
        </span>
    </a>
);


const HomePageNavMenu = (props: NavMenuProps) => {
    const [showScrolledNavMenu, setShowScrolledNavMenu] = useState(false);
    const [opacity, setOpacity] = useState(0);

    useEffect(() => {
        const scrollHandler = () => {
            const endBannerId = "home__banner-end";
            const showScrolled = !isInViewPort(endBannerId);
            if (showScrolledNavMenu !== showScrolled) {
                setShowScrolledNavMenu(showScrolled);
            }
            if (showScrolledNavMenu && opacity <= 100) {
                const t = document.getElementById(endBannerId);
                if (!!t) {
                    //note: once we scroll past the element the top value
                    //will start to go negative
                    const top = -t.getBoundingClientRect().top;
                    const fullOpacityAtOffset = 50;
                    setOpacity(top / fullOpacityAtOffset);
                }
            }
        };
        document.addEventListener('scroll', scrollHandler, true);
        return () => document.removeEventListener('scroll', scrollHandler, true);
    });

    const scrolledClassName = showScrolledNavMenu ? 'd-flex' : 'hide';
    return (
        <Fragment>
            <HomePageNavMenuAtTop {...props} />
            <HomePageNavMenuScrolled
                style={{ opacity: opacity, zIndex: 3 }}
                className={scrolledClassName}
                {...props}
            />
        </Fragment>
    );
};

const HomePageNavMenuAtTop = (props: NavMenuProps) => (
    <Navbar className="nav-menu nav-menu__simple pr-30px pl-30px" style={{ zIndex: 4 }}>
        <div className="d-flex w-100 justify-content-between align-items-center">
            <NavbarBrand tag={Link} to="/">
                <Logos colorSmall="#005288" colorBig="white"/>
            </NavbarBrand>
            {!props.isTempLoggedIn ? LogInLogOutButton(props) : <Fragment />}
        </div>
    </Navbar>
);

const Logos = (props: {colorBig: string, colorSmall: string}) => (
    <Fragment>
        <LogoBig color={props.colorBig} className="d-none d-sm-block" />
        <LogoSmall color={props.colorSmall} className="d-block d-sm-none" />
    </Fragment>
)

interface NavMenuScrolledProps {
    className?: string;
    style?: object;
}

const HomePageNavMenuScrolled = (props: NavMenuProps & NavMenuScrolledProps) => (
    <Navbar
        style={props.style}
        className={classNames("nav-menu__scrolled fixed-top pr-30px pl-30px", props.className)}
    >
        <div className="d-flex w-100 justify-content-between align-items-center">
            <NavbarBrand tag={Link} to="/" className="d-none d-sm-block">
                <LogoMedium color="#005288" />
            </NavbarBrand>
            <Button
                className="nav-menu__unauthenticated-become-vendor"
                onClick={() => window.scrollTo(0, 0)}
            >
                Become a Safeguard Contractor
            </Button>
        </div>
    </Navbar>
);

const LogInLogOutButton = (props: NavMenuProps) => {
    if (props.isLoggedIn && !props.isContentPage) {
        return (
            <NavItem className="navbar-nav">
                <NavLink className="nav-menu__logout" onClick={props.logOut}>
                    Log Out
                </NavLink>
            </NavItem>
        )
    }
    if (props.isLoggedIn && props.isContentPage) {
        return (
            <NavItem className="navbar-nav">
                <BackToDashboard {...props}/>
            </NavItem>
        )
    }
    if (!props.isLoginPage) {
        return (
            <NavLink
                to={Routes.login}
                tag={Link}
                className={!props.isContentPage ? "nav-menu__login" : "nav-menu__login--dark"}
            >
                Log In
            </NavLink>
        );
    }
    return <Fragment />
};

export default connect(
    (state: ApplicationState & { router: RouterState }) => {
        if (!state.user) {
            return {
                isLoggedIn: false,
                isTempLoggedIn: false,
                isLoginPage: false,
                isHomePage: false,
                isDashboard: false,
                isDashboardStep: false,
                isSimpleScaffoldPage: false,
                isContentPage: false
            };
        }
        let route = '';
        if (state && state.router && state.router.location && state.router.location.pathname) {
            const path = state.router.location.pathname;
            const excludingLeadingSlash = path.slice(1);
            const nextSlash = excludingLeadingSlash.indexOf('/');
            if (nextSlash === -1) {
                route = path;
            } else {
                route = path.slice(0, excludingLeadingSlash.indexOf('/') + 1);
            }
        }
        return {
            isLoggedIn: isLoggedIn(),
            isTempLoggedIn: isTempLoggedIn(),
            isLoginPage: route === Routes.login,
            isHomePage: route === Routes.home,
            isDashboard: route === Routes.dashboard,
            isSimpleScaffoldPage: [
                Routes.login,
                Routes.createPassword,
                Routes.forgotPassword,
                Routes.requestForgotPassword
            ].includes(route),
            isContentPage: [
                Routes.faq,
                Routes.aboutUs,
                Routes.privacyPolicy,
                Routes.termsOfUse,
                Routes.contactUs
            ].includes(route),
            isDashboardStep: [
                Routes.companyInfo,
                Routes.identification,
                Routes.backgroundCheck,
                Routes.insurance,
                Routes.terms,
                Routes.complete
            ].includes(route),
        };
    },
    {
        logOut: ActionCreators.logOut,
        goToDashboard: ActionCreators.goToDashboard
    }
)(NavMenu);