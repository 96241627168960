import React, {ReactNode, Fragment} from 'react';
import {connect} from 'react-redux';
import {Col, Row} from "reactstrap";
import "./styles.scss";
import {
    ActionCreators,
    UserFields
} from "../../../store/User";
import {ApplicationState} from "../../../store";
import {API, DispatchProps} from "../../../api";
import {CheckIcon, GrassIcon, HouseIcon, ToolboxIcon} from "../../../assets/icons";
import classNames from "classnames";
import {Title, Message} from '../../lib/Copy';

interface StateProps {
    workTypeGrassAndSnow: boolean;
    workTypeInspections: boolean;
    workTypeMaintenance: boolean;
}

type Props = StateProps & DispatchProps;

interface WorkCardProps {
    onClick?: () => void;
    className?: string;
    icon?: () => ReactNode;
    title: string;
    checked?: boolean;
    message: string;
}

const WorkCard = (props: WorkCardProps) => {
    return (
        <div className="types-of-work-section__selection-wrapper mb-2 mb-md-0">
            <div
                className={classNames("types-of-work-section__selection mb-1 mb-md-2", props.className)}
                onClick={props.onClick}>
                <div className="types-of-work-section__selection--items">
                    {props.icon && props.icon()}
                    <span className="pl-20px pl-md-0 types-of-work-section__selection--title">{props.title}</span>
                    <div style={{visibility: props.checked ? "visible" : "hidden"}}
                         className="ml-auto ml-md-0 pr-20px pr-md-0">
                        <CheckIcon/>
                    </div>
                </div>
            </div>
            <Message textCenter className="pt-0 pt-md-2">{props.message}</Message>
        </div>
    );
};


const TypesOfWork = (props: Props) => {
    const iconGray = "#a4a4a4";

    const handleCheckbox = (
        name: string,
        checked: boolean
    ) => {
        props.setField(name, checked);
        props.putField({name, data: checked.toString()});
    };

    return (
        <div className="types-of-work-section mt-50px">
            <Row className="pb-30px">
                <Col xs="12">
                    <Title>Types Of Work</Title>
                    <Message>
                        What type of work you are interested in?
                    </Message>
                    <p className="types-of-work-section__sub-header">
                        Select all that apply.*
                    </p>
                </Col>
            </Row>
            <div className="types-of-work-section__cards">
                <WorkCard
                    className={classNames(props.workTypeInspections ? "active" : "", "pl-20px pl-md-0 mb-1 mb-md-2")}
                    onClick={() => {
                        const nextState = !props.workTypeInspections;
                        handleCheckbox(UserFields.WORK_TYPE_INSPECTIONS, nextState);
                    }}
                    checked={props.workTypeInspections}
                    title="Inspections"
                    message="Verify occupancy and report condition/damages"
                    icon={() =>
                        <Fragment>
                            <HouseIcon scale={0.85} color={props.workTypeInspections ? "white" : iconGray}
                                       className="d-block d-md-none"/>
                            <HouseIcon color={props.workTypeInspections ? "white" : iconGray}
                                       className="d-none d-md-block"/>
                        </Fragment>
                    }
                />
                <WorkCard
                    className={classNames(props.workTypeGrassAndSnow ? "active" : "", "pl-20px pl-md-0 mb-1 mb-md-2")}
                    onClick={() => {
                        const nextState = !props.workTypeGrassAndSnow;
                        handleCheckbox(UserFields.WORK_TYPE_GRASS_AND_SNOW, nextState);
                    }}
                    checked={props.workTypeGrassAndSnow}
                    title="Grass & Snow"
                    message="Lawn mowing, general yard work, and snow removal"
                    icon={() =>
                        <Fragment>
                            <GrassIcon scale={0.85} color={props.workTypeGrassAndSnow ? "white" : iconGray}
                                       className="d-block d-md-none"/>
                            <GrassIcon color={props.workTypeGrassAndSnow ? "white" : iconGray}
                                       className="d-none d-md-block"/>
                        </Fragment>
                    }
                />
                <WorkCard
                    className={classNames(props.workTypeMaintenance ? "active" : "", "pl-20px mb-1 mb-md-2 pl-md-0")}
                    onClick={() => {
                        const nextState = !props.workTypeMaintenance;
                        handleCheckbox(UserFields.WORK_TYPE_MAINTENANCE, nextState);
                    }}
                    checked={props.workTypeMaintenance}
                    title="Maintenance"
                    message="Traditional preservation and general home repair"
                    icon={() =>
                        <Fragment>
                            <ToolboxIcon scale={0.85} color={props.workTypeMaintenance ? "white" : iconGray}
                                         className="d-block d-md-none"/>
                            <ToolboxIcon color={props.workTypeMaintenance ? "white" : iconGray}
                                         className="d-none d-md-block"/>
                        </Fragment>
                    }
                />
            </div>
        </div>
    );
};

const mapStateToProps = (state: ApplicationState) => {
    if (state === undefined || state.user === undefined) {
        return {
            workTypeGrassAndSnow: false,
            workTypeInspections: false,
            workTypeMaintenance: false
        };
    }
    const user = state.user;
    return {
        workTypeGrassAndSnow: user.workTypeGrassAndSnow || false,
        workTypeInspections: user.workTypeInspections || false,
        workTypeMaintenance: user.workTypeMaintenance || false
    };
};

export default connect(
    mapStateToProps,
    {
        setField: ActionCreators.setField,
        putField: API.putField
    }
)(TypesOfWork);
