import React from "react";
import {Link} from "react-router-dom";
import {
    CaretIcon,
    ProgressCircleCompleteIcon,
    ProgressCircleFailedIcon, ProgressCircleIcon,
    ProgressCircleInProgressIcon
} from "../../../assets/icons";

export enum LinkState {
    Unknown = 0,
    InProgress,
    Complete,
    Failed
}

export interface DashboardLinkProps {
    route: string;
    title: string;
    state?: LinkState
}

export const DashboardLink = (props: DashboardLinkProps) => {
    return (
        <Link to={props.route}
              className="dashboard__link d-flex align-items-center justify-content-between mb-3 mr-4"
        >
            <span className="d-flex align-items-center">
                {getLinkIcon(props.state)}

                <span className="pl-4 roboto-fi-bug">
                    {props.title}
                </span>
            </span>
            <CaretIcon/>
        </Link>
    );
};

export const getLinkIcon = (state?: LinkState) => {
    switch (state) {
        case LinkState.InProgress:
            return <ProgressCircleInProgressIcon/>;
        case LinkState.Complete:
            return <ProgressCircleCompleteIcon/>;
        case LinkState.Failed:
            return <ProgressCircleFailedIcon/>;
        default:
            return <ProgressCircleIcon/>;
    }
};
