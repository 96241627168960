import {all, fork} from 'redux-saga/effects';
import {apiSaga} from './api';
import {loginSaga} from './login';
import {navigationSaga} from "./navigation";

export default function* rootSaga() {
    yield all([
        fork(apiSaga),
        fork(loginSaga),
        fork(navigationSaga)
    ]);
}