import React, { ReactNode } from 'react';
import { CaretIcon } from '../../../assets/icons';
import classNames from 'classnames';
import "./styles.scss";

interface ExternalLinkProps {
    base64Data: string;
    className?: string;
    withCaret?: boolean;
    children: ReactNode;
}

export default (props: ExternalLinkProps) => {
    return (
        <a
            target="_blank"
            download="terms.pdf"
            href={window.URL.createObjectURL(b64toBlob(props.base64Data))}
            rel="noopener noreferrer"
            className={classNames("external-link", props.className)}
        >
            {props.children}
            {
                !!props.withCaret
                && <CaretIcon color="#8DBAE6" className="external-link__caret-icon" />
            }
        </a>
    );
}

const b64toBlob = (b64Data: string, sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: 'application/pdf' });
    return blob;
}
// function saveFile(name: string, blob: Blob) {
//     if (blob !== null && navigator.msSaveBlob)
//         return navigator.msSaveBlob(blob, name);
//     var a = $("<a style='display: none;'/>");
//     var url = window.URL.createObjectURL(blob);
//     a.attr("href", url);
//     a.attr("download", name);
//     $("body").append(a);
//     a[0].click();
//     window.URL.revokeObjectURL(url);
//     a.remove();
// }