import React from "react";
import {Link} from "react-router-dom";
import {Routes} from "../../../routes";
import {Col, Container, Row} from "reactstrap";
import "./styles.scss";
interface CtaBannerProps {
    title: string;
    text: string;
}

export function CtaBanner(props: CtaBannerProps) {
    return (
        <div className="cta-banner d-flex w-100">
            <Container className="d-flex w-100">
                <Row className="align-self-center">
                    <Col xs="12" 
                        lg={{size: 10, offset: 1}} 
                        className="text-center align-self-center">
                        <h2 className="cta-banner--title">
                            {props.title}
                        </h2>
                    </Col>
                    <Col xs="12" 
                        lg={{size: 10, offset: 1}} 
                        className="text-center align-self-center">
                        <p>{props.text}</p>
                        <Link to={Routes.home} className="cta-banner--link">Sign Up Now</Link>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};
