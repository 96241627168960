import React from 'react';
import { Col, Container, Row } from "reactstrap";
import { Banner } from "../lib/Banner";
import { CtaBanner } from "../lib/CtaBanner";
import Locations from "./Locations";
import { ContactPhoneIcon, ContactEmailIcon } from "../../assets/icons";
import BasicContent from '../lib/BasicContent';

export default () => {
    return (
        <BasicContent className="contact-us__wrapper">
            <Banner title={'Contact Us'} />
            <Container className="contact-us">
                <Row className="contact-us__body">
                    <Col xs="12"
                        md="6"
                        className="contact-block d-flex align-items-start justify-content-center">
                        <div className="text-center">
                            <ContactPhoneIcon className="icon-image" />
                            <h3>Phone</h3>
                            <p>
                                <strong>General: </strong> <a href="tel:+18008528306;2192">800.852.8306 EXT. 2192</a><br />
                                <strong>IT Technical Support: </strong><a href="tel:+18008528306;3999">800.852.8306 EXT. 3999</a>
                            </p>
                        </div>
                    </Col>
                    <Col xs="12"
                        md="6"
                        className="contact-block d-flex align-items-start justify-content-center">
                        <div className="text-center">
                            <ContactEmailIcon className="icon-image" />
                            <h3>Email</h3>
                            <p>
                                <a href="mailto:vm.leads@safeguardproperties.com">vm.leads@safeguardproperties.com</a>
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Locations />
            <CtaBanner title={'Do Work That Matters'} text={'Join Safeguard\'s elite vendor network and be your own boss, grow your business, make more money, all while helping your community.'} />
        </BasicContent>
    );
};
