import React, {useState} from "react";

interface VideoWithOverlayProps {
    videoImage: string;
    videoUrl: string;
    className?: string;
    elementClassName?: string;
    showOverlay?: boolean;
}

export default (props: VideoWithOverlayProps) => {
    const [clicked, setClick] = useState(false);
    return (
        <div className={props.className}>
            {
                !!props.showOverlay &&
                <img
                    alt="elite team video"
                    src={props.videoImage}
                    className={props.elementClassName}
                    style={{display: clicked ? "none" : "block"}}
                    onClick={() => setClick(!clicked)}
                />
            }
            <iframe
                title="home page highlight video"
                className={props.elementClassName}
                style={{display: clicked || !props.showOverlay ? "block" : "none"}}
                src={clicked || !props.showOverlay ? props.videoUrl : ""}
                allow="encrypted-media; autoplay;"
                frameBorder="0"
            />
        </div>
    );

}
