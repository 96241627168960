import {Action, Reducer} from "redux";

export interface MessagesState {
    forgotPasswordTokenExpired?: boolean;
}

export interface MessageAction {
    type: string;
    payload: string;
}

export const TOKEN_EXPIRED = 'TOKEN_EXPIRED';

export const ActionCreator = {
    setTokenExpired: (isExpired: boolean) => ({type: TOKEN_EXPIRED, payload: isExpired})
};

export const messagesReducer: Reducer<MessagesState> = (state: MessagesState | undefined, incomingAction: Action): any => {
    const action = incomingAction as MessageAction;
    if (state === undefined) {
        return {
            forgotPasswordTokenExpired: ''
        }
    }
    
    switch (action.type) {
        case TOKEN_EXPIRED:
            return {...state, forgotPasswordTokenExpired: action.payload};
        default:
            return state;
    }
};
