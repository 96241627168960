import React from "react";
import {Link} from "react-router-dom";
import {Routes} from "../../../routes";
import {Col, Container, Row} from "reactstrap";

export default () => (
    <Container className="home__contact-us d-flex w-100">
        <Row className="align-self-center">
            <Col xs="12" lg={{size: 8, offset: 2}} className="text-center align-self-center">
                <h1 className="home__contact-us--title">
                    Want to Know More About Working With Safeguard?
                </h1>
            </Col>
            <Col xs="12" lg={{size: 8, offset: 2}} className="text-center align-self-center">
                <Link to={Routes.contactUs} className="home__contact-us--link">Contact Us</Link>
            </Col>
        </Row>
    </Container>
);
