import React, { useState } from 'react';
import LoginForm from "./LoginForm";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import { Col, Row } from "reactstrap";
import SimplePageScaffold from "../lib/SimplePageScaffold";
import { WithLoader } from '../lib/WithLoader';

interface StateProps {
    isLoading: boolean;
    forgotPasswordTokenExpired?: boolean;
}

type Props = StateProps;

const LoginPage = (props: Props) => {
    const [title, setTitle] = useState('Log In');
    const errorMessage = props.forgotPasswordTokenExpired ? "The forgot password link expired." : '';
    return (
        <SimplePageScaffold className="login py-5">
            <Row className="w-100">
                <Col xs="12">
                    <h1 className="text-center mb-5">{title}</h1>
                </Col>
                <Col sm={{ offset: 2, size: 8 }} xs="12">
                    <WithLoader isLoading={props.isLoading} style={{ minHeight: "278px", height: "278px" }}>
                        <p className="login__link-expired">
                            {errorMessage}
                        </p>
                        <LoginForm
                            onContinue={() => setTitle('Logging In')}
                            onFailure={() => setTitle('Log In')}
                        />
                    </WithLoader>
                </Col>
            </Row>
        </SimplePageScaffold>
    );
};

export default connect(
    (state: ApplicationState) => {
        if (state === undefined || state === null) {
            return {
                isLoading: true
            };
        }

        return {
            isLoading: state.api.isFetching,
            forgotPasswordTokenExpired: state.messages.forgotPasswordTokenExpired
        };
    },
)(LoginPage);
