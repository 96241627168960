import {Action, Reducer} from 'redux';
import {
    ContinueFromRoute,
    createSetActionType,
    createSetField,
    FieldAction,
    GenericAction
} from "./index";
import {createApiActions} from "./api";
import {hasContent} from "../lib/helpers";

// -----------------
// STATE - This defines the type of data maintained in the Redux store.
export interface UserState {
    id?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    phone?: string;
    zip?: string;
    token?: string;

    addressLine1?: string;
    addressLine2?: string;
    city?: string;
    state?: string;
    referral?: string;
    ein?: string;

    workTypeGrassAndSnow?: boolean;
    workTypeInspections?: boolean;
    workTypeMaintenance?: boolean;

    workLocationRequests?: string;

    photoIdUploaded?: boolean;
    photoIdUploadFailed?: boolean;

    backgroundCheckUploaded?: boolean;
    backgroundCheckUploadFailed?: boolean;
    requestBackgroundCheck?: boolean;
    willObtainBackgroundCheck?: boolean;

    insuranceUploaded?: boolean;
    insuranceUploadFailed?: boolean;
    requestInsurance?: boolean;
    willObtainInsurance?: boolean;

    tmcVersion?: string;
    tmcText?: string;
    tmcVersionAccepted?: string;
    tmcPdf?: string;

    externalLeadRequest?: string;

    viewedCompletionPage?: boolean;

    company?: string;
    titleInCompany?: string;
    isFastTracked?: boolean;
    tmcDate?: string | null;
}

export interface CreatePassword {
    email: string;
    password: string;
}

export const UserFields = {
    FIRST_NAME: 'firstName',
    LAST_NAME: 'lastName',
    EMAIL: 'email',
    PHONE: 'phone',
    ZIP: 'zip',
    ADDRESS_LINE1: 'addressLine1',
    ADDRESS_LINE2: 'addressLine2',
    CITY: 'city',
    STATE: 'state',
    REFERRAL: 'referral',
    EIN: 'ein_number',
    WORK_TYPE_GRASS_AND_SNOW: 'workTypeGrassAndSnow',
    WORK_TYPE_INSPECTIONS: 'workTypeInspections',
    WORK_TYPE_MAINTENANCE: 'workTypeMaintenance',
    WORK_IN_OTHER_LOCATIONS: 'workInOtherLocations',
    WORK_LOCATION_REQUESTS: 'workLocationRequests',
    IDENTIFICATION_UPLOADED: 'identificationUploaded',
    TMC_VERSION_ACCEPTED: 'tmcVersionAccepted',

    EXTERNAL_LEAD_REQUEST: 'externalLeadRequest',
    VIEWED_COMPLETION_PAGE: 'viewedCompletionPage',
    COMPANY: 'company',
    TITLE_IN_COMPANY: 'titleInCompany',
    TMCDATE:'tmcDate'
};

const USER = 'USER';
const ACCOUNT = 'ACCOUNT';
const ACCOUNT_PASSWORD = 'ACCOUNT_PASSWORD';
const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
const FIELD = 'FIELD';
const DOCUMENT = 'DOCUMENT';

export const LOGOUT = 'LOGOUT';
export const LOGIN = 'LOGIN';

export const Actions = {
    SET_FIRST_NAME: createSetActionType(UserFields.FIRST_NAME),
    SET_LAST_NAME: createSetActionType(UserFields.LAST_NAME),
    SET_EMAIL: createSetActionType(UserFields.EMAIL),
    SET_PHONE: createSetActionType(UserFields.PHONE),
    SET_ZIP: createSetActionType(UserFields.ZIP),
    SET_USER: createSetActionType(USER),
    SET_ADDRESS_LINE1: createSetActionType(UserFields.ADDRESS_LINE1),
    SET_ADDRESS_LINE2: createSetActionType(UserFields.ADDRESS_LINE2),
    SET_CITY: createSetActionType(UserFields.CITY),
    SET_STATE: createSetActionType(UserFields.STATE),
    SET_REFERRAL: createSetActionType(UserFields.REFERRAL),
    SET_EIN: createSetActionType(UserFields.EIN),
    SET_WORK_TYPE_GRASS_AND_SNOW: createSetActionType(UserFields.WORK_TYPE_GRASS_AND_SNOW),
    SET_WORK_TYPE_INSPECTIONS: createSetActionType(UserFields.WORK_TYPE_INSPECTIONS),
    SET_WORK_TYPE_MAINTENANCE: createSetActionType(UserFields.WORK_TYPE_MAINTENANCE),
    SET_WORK_IN_OTHER_LOCATIONS: createSetActionType(UserFields.WORK_IN_OTHER_LOCATIONS),
    SET_WORK_LOCATION_REQUESTS: createSetActionType(UserFields.WORK_LOCATION_REQUESTS),
    SET_IDENTIFICATION_UPLOADED: createSetActionType(UserFields.IDENTIFICATION_UPLOADED),
    SET_EXTERNAL_LEAD_REQUEST: createSetActionType(UserFields.EXTERNAL_LEAD_REQUEST),
    SET_VIEW_COMPLETION_PAGE: createSetActionType(UserFields.VIEWED_COMPLETION_PAGE),
    SET_COMPANY: createSetActionType(UserFields.COMPANY),
    SET_TITLE_IN_COMPANY: createSetActionType(UserFields.TITLE_IN_COMPANY),
    CONTINUE_FROM_PAGE: 'CONTINUE_FROM_PAGE',
    GO_TO_DASHBOARD: 'GO_TO_DASHBOARD',
    LOGOUT,

    ...createApiActions(USER, 'GET'),
    ...createApiActions(ACCOUNT, 'POST'),
    ...createApiActions(ACCOUNT_PASSWORD, 'POST'),
    ...createApiActions(FORGOT_PASSWORD, 'POST'),
    ...createApiActions(DOCUMENT, 'POST'),
    ...createApiActions(LOGIN, 'POST'),
    ...createApiActions(FIELD, 'PUT'),
    ...createApiActions(DOCUMENT, 'DELETE')
} as any;


// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = FieldAction | GenericAction;

export const ActionCreators = {
    setField: (name: string, data?: any) => (createSetField(name, data)),
    logOut: () => ({type: Actions.LOGOUT, payload: {}}),

    continueFromRoute: (currentRoute: string) => ({
        type: Actions.CONTINUE_FROM_PAGE,
        payload: {
            currentRoute
        }
    } as ContinueFromRoute),

    goToDashboard: () => ({type: Actions.GO_TO_DASHBOARD, payload: {}}),
};

// ----------------
// REDUCER - For a given state and action, returns the new state.

export const reducer: Reducer<UserState> = (state: UserState | undefined, incomingAction: Action): UserState => {
    if (state === undefined) {
        return {} as UserState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case Actions.GET_USER_SUCCESS:
        case Actions.SET_USER:
            const userAction = incomingAction as GenericAction;
            return {...state, ...userAction.payload.data.data};
    }

    if (action.type.startsWith('SET_')) {
        const fieldAction = incomingAction as FieldAction;
        return {...state, [fieldAction.payload.name]: fieldAction.payload.data};
    }
    return state;
};

export function didUserAcceptTerms(user: UserState) {
    return hasContent(user.tmcVersionAccepted)
        && user.tmcVersion === user.tmcVersionAccepted;
}

export const isCompanyInfoComplete = (user: UserState) =>
    hasContent(user.addressLine1)
    && hasContent(user.city)
    && hasContent(user.state)
    && hasContent(user.zip)
    && hasContent(user.referral)
    && hasWorkType(user);

export const isCompanyInfoInProgress = (user: UserState) =>
    (!isCompanyInfoComplete(user)) && (
        hasContent(user.addressLine1)
        || hasContent(user.city)
        || hasContent(user.state)
        || hasContent(user.zip)
        || hasContent(user.referral)
        || hasWorkType(user)
    );

export const isPhotoIdComplete = (user: UserState) => user.photoIdUploaded;
export const isBackgroundCheckComplete = (user: UserState) =>
    user.backgroundCheckUploaded ||
    user.requestBackgroundCheck ||
    user.willObtainBackgroundCheck;
export const isInsuranceComplete = (user: UserState) =>
    user.insuranceUploaded ||
    user.requestInsurance ||
    user.willObtainInsurance;

export const isAtLeastOneSteComplete = (user: UserState) =>
    isCompanyInfoComplete(user) ||
    isPhotoIdComplete(user) ||
    isBackgroundCheckComplete(user) ||
    isInsuranceComplete(user) ||
    didUserAcceptTerms(user);

export const areAllStepsComplete = (user: UserState) =>
    isCompanyInfoComplete(user) &&
    isPhotoIdComplete(user) &&
    isBackgroundCheckComplete(user) &&
    isInsuranceComplete(user) &&
    didUserAcceptTerms(user);

const hasWorkType = (user: UserState) => (user.workTypeGrassAndSnow || user.workTypeInspections || user.workTypeMaintenance);
