import React, {Fragment, useState} from 'react';
import NavMenu from './NavMenu';
import Footer from './Footer';
import {isIE} from 'react-device-detect'
import {RawButton} from "./Form";
import {Message} from "./lib/Copy";

export default (props: { children?: React.ReactNode }) => (
    <React.Fragment>
        <div className="d-flex h-100 w-100 flex-column justify-content-between">
            <NavMenu/>
            {props.children}
            <IeMessage/>
            <Footer/>
        </div>
    </React.Fragment>
);

const IeMessage = () => {
    let [show, setShow] = useState(true);
    if (!isIE) return <Fragment/>
    return show ? (
        <Fragment>
            <div className="ie-msg-background">
                <div className="ie-msg">
                    <h2 style={{fontWeight: 700, fontSize: '1.875rem', letterSpacing: 0.01, color: '#005288'}}
                        className="mb-30px">
                        It appears that you're using an unsupported browser
                    </h2>
                    <Message textCenter className="mb-50px">
                        If you are currently using Internet Explorer, we recommend that you switch to Edge, Chrome, or
                        Firefox to enjoy the best experience SafeguardVendors.com has to offer.
                    </Message>
                    <div className="d-flex justify-content-center">
                        <div style={{width: '140px'}}>
                            <RawButton onClick={() => setShow(false)} center>
                                <span style={{textAlign: 'center'}}>OK</span>
                            </RawButton>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    ) : <Fragment/>;
}
