import React, { useEffect } from 'react';
import Banner from "./Banner";
import Testimonial1 from "../../assets/testimonial1.png";
import Testimonial2 from "../../assets/testimonial2.png";
import Testimonial3 from "../../assets/testimonial3.png";
import OneColumnBlock from "./OneColumnBlock";
import Videos from "./Videos";
import ContactUs from "./ContactUs";
import { Testimonial, Testimonials } from "./Testimonials";
import { UserFields, ActionCreators } from '../../store/User';
import { connect } from 'react-redux';
import { hasContent } from '../../lib/helpers';

const testimonials = [{
    image: Testimonial1,
    message: "\"Since coming on board with Safeguard my staff has doubled–I am proud to provide them and their families with a stable income.\"",
    name: "Maintenance Contractor",
    location: "California",
    active: true
}, {
    image: Testimonial2,
    message: "\"I enjoy the compliments I receive from people in the community when I increase the curb appeal of their neighborhoods.\"",
    name: "Inspections Contractor",
    location: "Ohio",
    active: true
}, {
    image: Testimonial3,
    message: "\"Safeguard allows me to work at my own pace, for myself, and with my family–and do something good for the community.\"",
    name: "Maintenance Contractor",
    location: "New Jersey",
    active: true
}] as Testimonial[];

interface OwnProps{
    referral?: string;
}

interface DispatchProps {
    setField: (name: string, data?: any) => void;
}

type Props = OwnProps & DispatchProps;

const HomePage = (props: Props) => {
    useEffect(() => {
        if (hasContent(props.referral)) {
            props.setField(UserFields.EXTERNAL_LEAD_REQUEST, props.referral);
        }
    }, []);

    return (
        <div className="home">
            <Banner />
            <div className="home__one-col-block--wrapper" />
            <OneColumnBlock className="home__one-col-block" />
            <Testimonials className="home__testimonials" testimonials={testimonials} />
            <div className="home__mortgage-field-services--wrapper">
                <Videos />
            </div>
            <div className="home__contact-us__wrapper">
                <ContactUs />
            </div>
        </div>
    );
};



export default connect(
    null, {
    setField: ActionCreators.setField,
}
)(HomePage);