import React, { Fragment, FormEvent, ReactNode, useState } from "react";
import Input from "reactstrap/lib/Input";
import Label from "reactstrap/lib/Label";
import classNames from "classnames";
import { UploadIcon, UploadErrorIcon, UploadSuccessIcon } from "../../../assets/icons";
import { hasContent } from "../../../lib/helpers";
import "./styles.scss";
import { PulseLoader } from "react-spinners";

interface UploadButtonProps {
    id: string;
    uploadFile: (file: File, fileId?: any) => void;
    className?: string;
    error?: string | null;
    success?: boolean | null;
    isUploading?: boolean | null;
    title?: string;
    onClick?: (clicked: boolean) => void;
    documentType?: string;
}

export default (props: UploadButtonProps) => {
    const hasError = hasContent(props.error);

    const className = hasError
        ? "upload-button--error"
        : props.success
            ? "upload-button--success"
            : "";

    return (
        <div className={classNames("upload-button", className, props.className)}>
            <UploadButtonInternal {...props}>
                <DefaultLabel {...props} />
            </UploadButtonInternal>
            {
                hasError &&
                <p className="upload-button__error-msg text-center">
                    {props.error}
                </p>
            }
            {
                props.success
                && <UploadButtonInternal {...props}>
                    <UploadNewFileLabel {...props} />
                </UploadButtonInternal>
            }
            <p className="upload-button__accepted-formats text-center ">
                Acceptable file types jpg, png, and pdf
            </p>
        </div>
    )
};

interface _UploadButtonProps {
    children: ReactNode;
}

const UploadButtonInternal = (props: UploadButtonProps & _UploadButtonProps) => {
    return (
        <Fragment>
            <Input
                type="file"
                style={{ opacity: 0, position: "absolute", pointerEvents: "none" }}
                name={props.id}
                id={props.id}
                onChange={async (e: FormEvent<HTMLInputElement>) => {
                    if (e && e.currentTarget && e.currentTarget.files) {
                        let file = e.currentTarget.files[0];
                        let docType = "";
                        if (props.documentType == "PHOTOIDENTIFICATION")
                            docType = "DOCUMENT_TYPE_PHOTO_IDENTIFICATION";
                        if (props.documentType == "BACKGROUNDCHECK")
                            docType = "DOCUMENT_TYPE_BACKGROUND_CHECK";
                        if (props.documentType == "GENEARALLIABILITYINSURANCE")
                            docType = "DOCUMENT_TYPE_INSURANCE";
                        let fileId = await fileUpload(file, docType);
                        props.uploadFile(file, fileId);
                    }
                }}
            />
            {props.children}
        </Fragment>
    );
};

const DefaultLabel = (props: UploadButtonProps) => {
    const title = props.title || "Upload File";
    const hasError = hasContent(props.error);
    const [clicked, setClicked] = useState(!!props.success);

    const getUploadIcon = () => (
        hasError
            ? <UploadErrorIcon className="upload-button__icon" />
            : !!props.success
                ? <UploadSuccessIcon className="upload-button__icon" />
                : <UploadIcon className="upload-button__icon" />
    );

    const getButtonContent = () => (
        props.isUploading
            ? <PulseLoader color={!!props.success ? "#ffffff" : "#005288"} />
            :
            <span
                className="d-flex align-items-center"
            >
                {getUploadIcon()}
                {
                    props.success && !hasContent(props.error)
                        ? <Fragment>File Successfully Uploaded</Fragment>
                        : <Fragment>{title}</Fragment>
                }
            </span>
    );

    return (
        <Label
            htmlFor={props.id}
            onClick={() => {
                const nextState = !clicked;
                setClicked(nextState);
                props.onClick && props.onClick(nextState);
            }}
            className="upload-button__input d-flex align-items-center justify-content-center"
        >
            {getButtonContent()}
        </Label>
    );
};

const UploadNewFileLabel = (props: UploadButtonProps) => {
    const [clicked, setClicked] = useState(!!props.success);
    return (
        <Label
            onClick={() => {
                const nextState = !clicked;
                setClicked(nextState);
                props.onClick && props.onClick(nextState);
            }}
            htmlFor={props.id}
            className="upload-button__input--new-file d-flex align-items-center justify-content-center"
        >
            Upload New File
        </Label>
    );
}