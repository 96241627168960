import React, {ReactNode} from "react";
import {Label} from "reactstrap";
import classNames from "classnames";
import "./styles.scss";

interface CheckBoxProps {
    id: string;
    className?: string;
    onChange?: (checked?: boolean) => void;
    checked?: boolean;
    children?: ReactNode;
}

export default (props: CheckBoxProps) => (
    <Label htmlFor={props.id} className={classNames("form-group checkbox-container", props.className)}>
        {props.children}
        <input
            type="checkbox"
            id={props.id}
            checked={props.checked}
            onChange={e => props.onChange && props.onChange(e.currentTarget.checked)}
        />
        <span className="checkmark"/>
    </Label>
);
