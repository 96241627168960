import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { fileToJsonPayload, getUploadErrorMsg, hasContent } from "../../lib/helpers";
import { Document, ApplicationState, Field } from "../../store";
import { API, documentType } from "../../api";
import { CollapseSection } from '../lib/CollapseSection';
import UploadButton from '../lib/UploadButton';
import RequestButton from '../lib/RequestButton';
import { ActionCreators } from '../../store/User';
import { Title, Message, SubTitle } from '../lib/Copy';
import ExternalLink from '../lib/ExternalLink';
import { Routes, ExternalLinks } from '../../routes';
import DashboardPageStepScaffold from '../lib/DashboardPageStepScaffold';


interface StateProps {
    isLoading: boolean;
    insuranceDocumentUploaded: boolean;
    insuranceDocumentFailed: boolean;
    uploading: boolean;
    willObtainInsurance: boolean;
    requestInsurance: boolean;
}

interface DispatchProps {
    postDocument: (document: Document, cloudFileId?: any) => void;
    putField: (field: Field) => void;
    continueFromRoute: (currentRoute: string) => void;
    postDocumentError: (document: Document) => void;
}

type Props = StateProps & DispatchProps;

const InsurancePage = (props: Props) => {
    const [errors, setErrors] = useState({
        tooLarge: false,
        wrongFormat: false
    });

    const [requestInsurance, setRequestInsurance] = useState(props.requestInsurance);
    const [willObtainInsurance, setWillObtaiInsurance] = useState(props.willObtainInsurance);

    useEffect(() => {
        setRequestInsurance(props.requestInsurance);
        setWillObtaiInsurance(props.willObtainInsurance);
    }, [props.willObtainInsurance, props.requestInsurance]);

    const uploadFile = (file: File, cloudFileId: any) => {
        if (cloudFileId != "uploadError") {
            fileToJsonPayload(file, documentType.insurance)
                .then(payload => {
                    setErrors({ tooLarge: false, wrongFormat: false });
                    props.postDocument(payload as Document, cloudFileId);
                })
                .catch(err => {
                    setErrors({ ...errors, ...err });
                });
        } else {
            fileToJsonPayload(file, documentType.insurance)
            .then(payload => {
                setErrors({ tooLarge: false, wrongFormat: false });
                props.postDocumentError(payload as Document);
            })
            .catch(err => {
                setErrors({ ...errors, ...err });
            });
        }
    };

    return (
        <DashboardPageStepScaffold
            className="insurance"
            isLoading={props.isLoading}
            onContinue={() => props.continueFromRoute(Routes.insurance)}
        >
            <Title>General Liability Insurance</Title>
            <Message>
                Upload or request General Liability and Errors & Omissions Insurance.
            </Message>
            <ExternalLink
                to={ExternalLinks.identification}
                withCaret
            >
                Review our Insurance Requirements
            </ExternalLink>
            <UploadButton
                id="insurance__document"
                title="Upload Proof of Insurance"
                className="mt-30px"
                success={props.insuranceDocumentUploaded && !props.insuranceDocumentFailed}
                uploadFile={uploadFile}
                error={getUploadErrorMsg({ ...errors, uploadFailed: props.insuranceDocumentFailed && !(requestInsurance || willObtainInsurance) })}
                isUploading={props.uploading}
                documentType={documentType.insurance}
            />
            <SubTitle className="mt-30px">Don't Have Insurance?</SubTitle>
            <Message className="mt-20px">
                Safeguard offers Quick Start - a program that allows you to join Safeguard's vendor 
                network while temporarily delaying the upfront costs of obtaining insurance.
            </Message>
            <CollapseSection
                title="Quick Start Terms & Conditions"
                className="mt-20px"
            >
                <div>
                    <p>
                        By electing Quick Start vendor status,
                        I understand and agree that Safeguard
                        is not providing my insurance and that
                        I am financially responsible for my business.
                    </p>
                </div>
            </CollapseSection>
            <RequestButton
                title="Request Quick Start"
                className="mt-20px"
                isClicked={requestInsurance}
                onClick={() => {
                    var clicked = !requestInsurance;
                    var data = clicked ? "QUICKSTART" : "NONE";
                    setRequestInsurance(clicked);
                    setWillObtaiInsurance(false);
                    setErrors({ tooLarge: false, wrongFormat: false });
                    props.putField({ name: documentType.insurance, data });
                }}
            />
            <OrSeparator />
            <RequestButton
                title="I Will Obtain Valid Insurance"
                className="mt-30px"
                subMessage="If you’d like to obtain insurance outside of this process"
                isClicked={willObtainInsurance}
                onClick={() => {
                    var clicked = !willObtainInsurance;
                    var data = clicked ? "NOVALID" : "NONE";
                    setWillObtaiInsurance(clicked);
                    setRequestInsurance(false);
                    setErrors({ tooLarge: false, wrongFormat: false });
                    props.putField({ name: documentType.insurance, data });
                }}
            />
        </DashboardPageStepScaffold>
    );
};

const OrSeparator = () => (
    <div className="d-flex justify-content-center align-items-center mt-30px">
        <span className="insurance__or-separator-line" />
        <p style={{ margin: 0, fontWeight: 500 }}>or</p>
        <span className="insurance__or-separator-line" />
    </div>
);

const mapStateToProps = (state: ApplicationState) => {
    if (state === undefined || state.user === undefined) {
        return {
            isLoading: true,
            insuranceDocumentUploaded: false,
            insuranceDocumentFailed: false,
            uploading: false,
            willObtainInsurance: false,
            requestInsurance: false
        };
    }

    var user = state.user;
    const doc = state.documentUpload;
    return {
        willObtainInsurance: user.willObtainInsurance,
        isLoading: !hasContent(user.email),
        insuranceDocumentUploaded: user.insuranceUploaded,
        insuranceDocumentFailed: user.insuranceUploadFailed,
        uploading: doc.insuranceUploading,
        requestInsurance: user.requestInsurance
    }
};

export default connect(
    mapStateToProps,
    {
        postDocument: API.postDocument,
        putField: (field: Field) => API.putField(field, true),
        continueFromRoute: ActionCreators.continueFromRoute,
        postDocumentError: API.postDocumentError
    }
)(InsurancePage as any);
