import React from "react";
import {Col, Container, Row} from "reactstrap";
import "./styles.scss";
interface BannerProps {
    title: string;
}

export function Banner(props: BannerProps) {
    return (
        <div className="basic-page__banner">
            <Container>
                <Row className="basic-page__banner--row text-center">
                    <Col xs="12" 
                         md={{offset: "1", size: "10"}} 
                         lg={{offset: "2", size: "8"}} 
                         className="align-self-end justify-content-center">
                        <h1 className="basic-page__banner--title">
                            {props.title}
                        </h1>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};
