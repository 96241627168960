import React, { ReactNode } from 'react';
import './styles.scss';
import classNames from 'classnames';

interface BasicContentProps {
    children: ReactNode;
    className?: string;
}

export default (props: BasicContentProps) => (
    <div className={classNames("basic-content flex-fill", props.className)}>
        {props.children}
    </div>
);