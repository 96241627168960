import {Action, Reducer} from "redux";

export interface ApiAction<T = {}> {
    type: string;
    payload: {
        isFetching: boolean;
        isSingleField?: boolean;
        getUserAfterCall?: boolean;
        method: string;
        url: string,
        requestData?: T,
        status?: number;
        data?: any
    }
}

export interface ApiState<T = {}> {
    isFetching: boolean;
    isSingleField?: boolean;
    getUserAfterCall?: boolean;
    response: {
        data?: object;
        errors?: string[];
        Meta?: object;
    }
}

export interface ApiStatusAction {
    type: string,
    payload: {
        status: number
    }
}

export const makeApiAction = <T>(type: string, method: string, url: string, requestData?: T, isSingleField?: boolean, getUserAfterCall?: boolean): ApiAction<T> => ({
    type,
    payload: {
        isFetching: false,
        isSingleField: isSingleField || false,
        getUserAfterCall: getUserAfterCall || false,
        method,
        url,
        requestData
    }
});

export const CALLING = 'CALLING';
export const FAILED = 'FAILED';
export const SUCCESS = 'SUCCESS';
export const API_STATUS_CODE = 'API_STATUS_CODE';

const createAction = (method: string, name: string, action?: string) => (`${method}_${name}` + (action ? `_${action}` : '')).toUpperCase();

export const createApiActions = (name: string, method: 'GET' | 'POST' | 'DELETE' | 'PUT') => {
    return ({
        [`${method}_${name}`]: createAction(method, name),
        [`${method}_${name}_CALLING`]: createAction(method, name, CALLING),
        [`${method}_${name}_FAILED`]: createAction(method, name, FAILED),
        [`${method}_${name}_SUCCESS`]: createAction(method, name, SUCCESS)
    });
};

export const apiReducer: Reducer<ApiState> = (state: ApiState | undefined, incomingAction: Action): any => {
    const action = incomingAction as ApiAction;

    if (state === undefined) {
        return {isFetching: false, isSingleField: false, getUserAfterCall: false};
    }
    const type = action.type || '';
    if (
        !type.startsWith('GET')
        && !type.startsWith('POST')
        && !type.startsWith('PUT')
        && !type.startsWith('DELETE')
    ) return state;

    const isFetching = action.payload.isFetching;
    const isSingleField = !!action.payload.isSingleField;
    const getUserAfterCall = !!action.payload.getUserAfterCall;
    if (type.includes('_SUCCESS') || type.includes('_FAILED')) {
        return {
            ...state,
            isFetching,
            isSingleField,
            getUserAfterCall,
            response: {
                data: action.payload.data.data,
                errors: action.payload.data.errors,
                meta: action.payload.data.meta
            }
        };
    }

    return {...state, isFetching, isSingleField};
};
