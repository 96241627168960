import React from 'react';
import { Col, Container, Row } from "reactstrap";
import { Banner } from "../lib/Banner";
import { CtaBanner } from "../lib/CtaBanner";
import BasicContent from '../lib/BasicContent';

export default () => {
    return (
        <BasicContent className="about-us__wrapper">
            <Banner title={'About Safeguard'} />
            <Container className="about-us">
                <Row className="about-us__body">
                    <Col xs="12"
                        md={{ offset: "1", size: "10" }}
                        lg={{ offset: "2", size: "8" }}>
                        <div className="">
                            <h2>Over 25 Years of Excellence</h2>
                            <p>Safeguard Properties is the mortgage field services industry leader, preserving vacant and foreclosed properties across the U.S., Puerto Rico, Virgin Islands and Guam. Founded in 1990 by Robert Klein and headquartered in Cleveland, Ohio, Safeguard provides the highest quality service to our clients by leveraging innovative technologies and proactively developing industry best practices and quality control procedures. Consistent with Safeguard’s values and mission, we are an active supporter of hundreds of charitable efforts across the country. Annually, Safeguard gives back to communities in partnership with our employees, vendors and clients. We also are dedicated to working with community leaders and officials to eliminate blight and stabilize neighborhoods. Safeguard is dedicated to preserving today and protecting tomorrow.</p>
                            <h2>Safeguard Lives by Four Simple Values</h2>
                            <p>Customer service and partnership. Safeguard is committed to delivering the highest quality services to clients, working in partnership with them to safeguard their interests, and upholding the value of all properties managed. Protecting and preserving neighborhoods. Safeguard is dedicated to protecting and preserving vacant and abandoned properties to prevent them from becoming a nuisance to neighboring properties or bringing blight to communities. Safeguard also supports efforts to prevent foreclosure and help troubled homeowners remain in their homes. Contributing to communities. Safeguard supports programs that help to maintain strong and vital neighborhoods, improve the quality of life for people of all ages, and provide a safe and happy environment for families to raise children. Respecting employees and contractors. Safeguard respects the nearly 1,700 employees and thousands of contractors nationally who join in our mission to deliver excellence in the work we complete. Safeguard is committed to providing employees and contractors with the training they need to do their jobs effectively, treating them with respect, providing a comfortable work environment, and doing our best to assure their safety in the field.</p>
                        </div>
                    </Col>
                </Row>
            </Container>
            <CtaBanner title={'Do Work That Matters'} text={'Join Safeguard\'s elite vendor network and be your own boss, grow your business, make more money, all while helping your community.'} />
        </BasicContent>
    );
};

