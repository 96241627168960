import React, { Fragment } from 'react';
import { connect } from "react-redux";
import { ApplicationState, Field } from "../../store";
import CheckBox from "../Form/CheckBox";
import { API } from "../../api";
import { ActionCreators, UserFields } from "../../store/User";
import { Row, Col } from "reactstrap";
import { hasContent, removeEmbeddedStyles } from "../../lib/helpers";
import classNames from 'classnames';
import { Routes } from '../../routes';
import DashboardPageStepScaffold from '../lib/DashboardPageStepScaffold';
import { DownloadIcon } from '../../assets/icons';
import DownloadLink from '../lib/ExternalLink/DownloadLink';

interface StateProps {
    isLoading?: boolean;
    tmcVersion?: string;
    tmcText?: string;
    tmcVersionAccepted?: string;
    tmcPdf?: string;
    tmcDate?:string | null;
}

interface DispatchProps {
    continueFromRoute: (currentRoute: string) => void;
    setField: (name: string, data?: any) => void;
    putField: (field: Field) => void;
}

type Props = StateProps & DispatchProps;

const TermsPage = (props: Props) => {
    let tmcText = !!props.tmcText
        ? removeEmbeddedStyles(props.tmcText)
        : '';
    return (
        <DashboardPageStepScaffold
            className="terms"
            isLoading={props.isLoading}
            hideButton
            xl={{ offset: 2, size: 8 }}
            onContinue={() => props.continueFromRoute(Routes.terms)}
            continueTitle="Finish"
        >

            <div className="d-flex flex-row-reverse">
                {
                    hasContent(props.tmcPdf)
                        ? <DownloadLink className="terms__download" base64Data={props.tmcPdf || ''}>
                            <DownloadIcon />
                            Download Terms
                        </DownloadLink>
                        : <Fragment />
                }
            </div>
            <Row>
                <Col xs="12">
                    <Terms text={tmcText} />
                </Col>
            </Row>
            <AcceptCheckBox {...props} />
        </DashboardPageStepScaffold>
    );
};

const Terms = (props: { text: string }) => (
    <div className="terms__box">
        <div
            className="terms__box-content"
            dangerouslySetInnerHTML={{ __html: props.text }}
        >
        </div>
    </div>
);

const AcceptCheckBox = (props: Props) => {
    const handleCheckbox = (
        name: string,
        value?: string | null
    ) => {
        props.setField(name, value);
        props.putField({ name, data: value });
    };


    const getDate = ()=>{
        var date = new Date();
        var year = date.getFullYear();
        var month = ''+(date.getMonth()+1);
        var day = ''+date.getDate();
        if(month.length < 2)
        {
            month = '0' + month;
        }
        if(day.length < 2)
        {
            day = '0' + day;
        }
        return [year, month, day].join('-');
    }

    const setTmcDate = (checked:any,props:Props)=>{
        if(checked && props.tmcDate){
           return true;
        }
        else if(checked && !props.tmcDate){
            handleCheckbox(UserFields.TMCDATE, getDate());
        }
        else if(!checked)
        {
            handleCheckbox(UserFields.TMCDATE, "");
        }
    }

    return (
        <div>
            <div className={classNames("mt-50px")}>
                <Row>
                    <Col xs="12" className="terms__accept">
                        <CheckBox
                            id="terms__accept--checkbox"
                            className="mt-20px"
                            checked={hasContent(props.tmcVersionAccepted)}
                            onChange={(checked) => {
                                checked
                                    ? handleCheckbox(UserFields.TMC_VERSION_ACCEPTED, props.tmcVersion)
                                    : handleCheckbox(UserFields.TMC_VERSION_ACCEPTED, "");
                                // checked
                                //     && handleCheckbox(UserFields.TMCDATE, getDate());
                                setTmcDate(checked,props);
                                
                            }}
                        >
                            I attest that I have authority to accept these terms and conditions
                            for and on behalf of the Contractor and therefore agree to the above Terms and Conditions.
                            </CheckBox>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default connect(
    (state: ApplicationState) => {
        if (!state.user) {
            return {
                isLoading: true,
                tmcVersion: '',
                tmcText: '',
                tmcVersionAccepted: '',
                tmcPdf: '',
            };
        }
        const user = state.user;
        return {
            isLoading: !hasContent(user.email),
            tmcVersion: user.tmcVersion,
            tmcText: user.tmcText,
            tmcVersionAccepted: user.tmcVersionAccepted,
            tmcPdf: user.tmcPdf,
            tmcDate: user.tmcDate
        };
    },
    {
        continueFromRoute: ActionCreators.continueFromRoute,
        putField: API.putField,
        setField: ActionCreators.setField
    }
)(TermsPage);
